import { NgModule, Injector } from "@angular/core";
import { CommonModule } from "@angular/common";
// import { CookieService } from "ngx-cookie-service";
// import { StorageService } from "./services/storage.service";
// import { HttpAdapterService } from "./services/http.adapter.service";
// import { ModelFactory } from "./crud/model.abstract";
// import {UserProvider} from "./services/authenticator.service";
// import { httpInterceptorProviders } from "./intercepters";
// import { formatDate } from "@angular/common";
import { HttpClientJsonpModule, HttpClientModule } from "@angular/common/http";

@NgModule({
  declarations: [],
  imports: [CommonModule, HttpClientModule, HttpClientJsonpModule],
  providers: [
    // CookieService,
    // StorageService,
    // HttpAdapterService,
    // UserProvider,
    // httpInterceptorProviders,
    // ModelFactory
  ],
})
export class ApiModule {
  constructor(private injector: Injector) {
    // const adapter: HttpAdapterService =
    //   injector.get<HttpAdapterService>(HttpAdapterService);
    // const storage: StorageService =
    //   injector.get<StorageService>(StorageService);
    // ModelFactory.init(adapter, storage, formatDate);
  }
}
