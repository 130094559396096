import { Injectable } from "@angular/core";
@Injectable({
  providedIn: "root",
})
export class AvailableSlotsAdapter {
  adapt(data) {
    let d = data?.map((item) => {
      return {
        ...item,
        dateTime: item?.date_time,
        workingTime: item?.working_times?.map((time) => {
          return {
            ...time,
            fromDate: this.fromDate(time),
            toDate: this.toDate(time),
          };
        }),
      };
    });
    return d;
  }

  fromDate(time) {
    return new Date(time?.date_time).getTime();
  }

  toDate(time) {
    return new Date(time["date_time"]).getTime() + time?.time_slot * 3600000;
  }
}
