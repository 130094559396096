import { Component, OnInit } from "@angular/core";
import { ActivatedRoute } from "@angular/router";
import { GoogleTagManagerService } from "angular-google-tag-manager";
import { Subject, takeUntil } from "rxjs";
import { environment } from "../../../../environments/environment";
import { SnackbarService } from "../../../atoms/components/snackbar/snackbar.service";
import { CheckoutService } from "../../../order-now/services/checkout.service";
import { parseService } from "../../../utils/utils";

@Component({
  selector: "app-thankyou-page",
  templateUrl: "./thankyou-page.component.html",
  styleUrls: ["./thankyou-page.component.scss"],
})
export class ThankyouPageComponent implements OnInit {
  _unsubscribeAll: Subject<void> = new Subject<void>();
  orderDetails: any;

  constructor(
    private activatedRoute: ActivatedRoute,
    private checkoutService: CheckoutService,
    private _snackbarService: SnackbarService,
    private gtmService: GoogleTagManagerService
  ) {}

  ngOnInit(): void {
    let id = this.activatedRoute.snapshot.paramMap.get("id");
    if (id) {
      this.fetchOrderDetails(id);
    }
    const gtmTag = {
      event: "conversion",
      pageName: `${environment.BASE_URL}/app/complete`,
      send_to: "AW-966114860/KkpDCPS3o9YDEKz81swD",
      value: 1.0,
      currency: "EUR",
      transaction_id: "",
    };
    this.gtmService.pushTag(gtmTag);
  }

  fetchOrderDetails(id: string) {
    this.checkoutService
      .fetchOrderDetails(id)
      .pipe(takeUntil(this._unsubscribeAll))
      .subscribe({
        next: (res) => {
          this.orderDetails = res;
        },
        error: (error) => {
          console.log(error);
        },
      });
  }

  parseService(items) {
    return parseService(items);
  }

  priceWithDiscount(basketTotal) {
    let appliedPromo = this.orderDetails?.coupon;
    if (!appliedPromo) {
      return parseFloat(basketTotal.toFixed(2));
    }
    if (appliedPromo.value_type === "percent") {
      basketTotal -= (basketTotal * parseFloat(appliedPromo.value)) / 100;
    }
    if (appliedPromo.value_type === "amount") {
      basketTotal -= parseFloat(appliedPromo.value);
    }
    if (basketTotal <= 0) {
      return 0;
    }
    return parseFloat(basketTotal.toFixed(2));
  }

  get getTotalAmount() {
    return this.priceWithDiscount(this.netAmount + this.getShippingCost) || 0;
  }

  get netAmount() {
    return this.orderDetails?.net_amount || 0;
  }

  get getShippingCost() {
    return this.netAmount < this.orderDetails?.settings?.shipping?.min_amount
      ? this.orderDetails?.settings?.shipping?.cost || 0
      : 0;
  }

  get getDiscountValue() {
    return this.getTotalAmount - (this.netAmount + this.getShippingCost);
  }
}
