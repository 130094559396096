import { NgModule } from "@angular/core";
import { CommonModule } from "@angular/common";
import { ButtonComponent } from "./components/button/button.component";
import { SnackbarComponent } from "./components/snackbar/snackbar.component";
import { MaterialModule } from "../shared/material/material.module";

const COMPONENTS = [ButtonComponent, SnackbarComponent];

const MODULES = [MaterialModule]

@NgModule({
  declarations: [...COMPONENTS],
  imports: [CommonModule,...MODULES],
  exports: [...COMPONENTS],
})
export class AtomsModule {}
