import { Pipe, PipeTransform } from "@angular/core";

@Pipe({
  name: "timeDate",
})
export class TimeDatePipe implements PipeTransform {
  transform(value: string): any {
    return new Date(
      new Date(value).getTime() + new Date().getTimezoneOffset() * 60 * 1000
    );
  }
}
