<div class="auth dflex">
    <div class="auth__left flexCol">
        <div class="auth__left-logo">
            <app-logo></app-logo>
        </div>
        <div class="auth__left-content flex alignCenter">
            <div class="auth__left-inner">
                <div class="authform">
                    <form [formGroup]="newPasswordForm">
                        <h3>Create new password</h3>
                        <div class="authform__field">
                            <div class="passwordwrap">
                                <mat-form-field>
                                    <mat-label>Enter new password</mat-label>
                                    <input matInput [type]="displayNewPassword ? 'text' :'password'" name="newPassword"
                                        formControlName="newPassword" />
                                    <span matsuffix class="eyeicon" (click)="viewPassword('new')">
                                        <img [src]="displayNewPassword ? '/assets/images/eye-crossed.svg' : '/assets/images/eye.svg'"
                                            class="Eye" alt="eye">
                                    </span>
                                </mat-form-field>
                                <mat-error
                                    *ngIf="newPasswordForm.controls.newPassword.touched && newPasswordForm.controls.newPassword.errors">
                                    {{ newPasswordErrorMessage }}
                                </mat-error>
                            </div>
                        </div>
                        <div class="authform__field">
                            <div class="passwordwrap">
                                <mat-form-field>
                                    <mat-label>Confirm new password</mat-label>
                                    <input matInput [type]="displayConfirmPassword ? 'text' :'password'"
                                        name="confirmPassword" formControlName="confirmPassword" />
                                    <span matsuffix class="eyeicon" (click)="viewPassword('confirm')">
                                        <img [src]="displayConfirmPassword ? '/assets/images/eye-crossed.svg' : '/assets/images/eye.svg'"
                                            class="Eye" alt="eye">
                                    </span>
                                </mat-form-field>
                                <mat-error
                                    *ngIf="newPasswordForm.controls.confirmPassword.touched && newPasswordForm.controls.confirmPassword.errors">
                                    {{ confirmErrorMessage }}
                                </mat-error>
                            </div>
                        </div>
                        <div class="authform__btn">
                            <app-button [label]="'Reset Password'" [showLoader]="clicked"
                                [isDisabled]="newPasswordForm.invalid || clicked" (action)="resetPassword()">
                            </app-button>
                        </div>
                    </form>
                </div>
            </div>
        </div>
    </div>
    <div class="auth__right">
    </div>
</div>