<div [ngSwitch]="type">
    <ng-container *ngSwitchCase="'primary'">
        <ng-container *ngTemplateOutlet="button; context: { $implicit: 'primary' }">
        </ng-container>
    </ng-container>
    <ng-container *ngSwitchCase="'secondary'">
        <ng-container *ngTemplateOutlet="button; context: { $implicit: 'secondary' }">
        </ng-container>
    </ng-container>
    <ng-container *ngSwitchCase="'white'">
        <ng-container *ngTemplateOutlet="button; context: { $implicit: 'white' }">
        </ng-container>
    </ng-container>
    <ng-container *ngSwitchCase="'cancel'">
        <ng-container *ngTemplateOutlet="button; context: { $implicit: 'cancel' }">
        </ng-container>
    </ng-container>
    <ng-container *ngSwitchCase="'outline'">
        <ng-container *ngTemplateOutlet="button; context: { $implicit: 'outline' }">
        </ng-container>
    </ng-container>
    <ng-container *ngSwitchDefault>
        <ng-container *ngTemplateOutlet="button"> </ng-container>
    </ng-container>
</div>
<ng-template #button let-type>
    <button (click)="action.emit($event)" [ngClass]="[class, type, mode, shape]" class="button" [disabled]="isDisabled">
        <ng-content select="[prefixContent]"></ng-content>
        <span>
            {{ label }}
        </span>
        <ng-content select="[postfixContent]"></ng-content>
        <div class="btn-loader" *ngIf="showLoader">
            <span class="btn-loader__dots"></span>
            <span class="btn-loader__dots"></span>
            <span class="btn-loader__dots"></span>
        </div>
    </button>
</ng-template>