import { Component, OnDestroy, OnInit } from "@angular/core";
import { FormBuilder, FormGroup, Validators } from "@angular/forms";
import { ActivatedRoute, Router } from "@angular/router";
import { Subject, takeUntil } from "rxjs";
// import { AuthService } from "../../../../pages/auth/services/auth.service";
import { SnackbarService } from "../../../atoms/components/snackbar/snackbar.service";
import { noWhitespaceValidator, passwordMatchValidator } from "../../../validators/validator";
import { AuthService } from "../../services/auth.service";

@Component({
  selector: "app-new-password",
  templateUrl: "./new-password.component.html",
  styleUrls: ["./new-password.component.scss"],
})
export class NewPasswordComponent implements OnInit, OnDestroy {
  newPasswordForm: FormGroup;
  displayConfirmPassword: boolean = false;
  displayNewPassword: boolean = false;
  clicked: boolean = false;
  private _unsubscribeAll: Subject<any> = new Subject<any>();

  constructor(
    private _fb: FormBuilder,
    public _router: Router,
    private _activatedRoute: ActivatedRoute,
    private authService: AuthService,
    private snackbarService: SnackbarService,
    private router: Router
  ) {}

  ngOnInit(): void {
    this.initForm();
  }

  initForm() {
    this.newPasswordForm = this._fb.group(
      {
        newPassword: [
          "",
          [
            Validators.required,
            Validators.minLength(6),noWhitespaceValidator
            // Validators.pattern(
            //   "(?=.*[a-z])(?=.*[A-Z])(?=.*[0-9])(?=.*[$@$!%*?&])[A-Za-zd$@$!%*?&].{7,}"
            // ),
            // Validators.maxLength(26),
          ],
        ],
        confirmPassword: ["", Validators.required],
      },
      {
        validator: passwordMatchValidator("newPassword", "confirmPassword"),
      }
    );
  }

  viewPassword(controlName: string) {
    if (controlName == "confirm") {
      this.displayConfirmPassword = !this.displayConfirmPassword;
    } else if (controlName == "new") {
      this.displayNewPassword = !this.displayNewPassword;
    }
  }

  resetPassword() {
    this.clicked = true;
    // const password = this.newPasswordForm.get("new_password").value;
    // const token = this._activatedRoute.snapshot.params["slug"];
    const payload = {
      token: this._activatedRoute.snapshot.params["slug"],
      password: this.newPasswordForm.get("newPassword").value,
    };
    this.authService
      .changePassword(payload)
      .pipe(takeUntil(this._unsubscribeAll))
      .subscribe({
        next: (res: any) => {
          this.newPasswordForm.reset();
          this.snackbarService.openSnackBar(res?.message);
          this.router.navigate(['/auth/login']);
          this.clicked = false;
        },
        error: (err) => {
          console.error(err);
          this.snackbarService.openSnackBar(err?.error.message, "error", 5000);
          this.clicked = false;
        },
      });
  }

  /**
   * On destroy
   */
  ngOnDestroy(): void {
    // Unsubscribe from all subscriptions
    this._unsubscribeAll.next(true);
    this._unsubscribeAll.complete();
  }

  get confirmErrorMessage() {
    let message = "";
    if (this.newPasswordForm.controls["confirmPassword"].errors?.required) {
      message = "This field is required";
    } else if (
      this.newPasswordForm.controls["confirmPassword"].errors?.maxlength
    ) {
      message = "Must be maximum 26 characters";
    } else if (
      this.newPasswordForm.controls["confirmPassword"].errors?.minlength
    ) {
      message = "Must be minimum 6 characters";
    } else if (
      this.newPasswordForm.controls["confirmPassword"].errors
        ?.confirmedValidator
    ) {
      message = "Passwords do not match";
    }
    return message;
  }

  get newPasswordErrorMessage() {
    let msg = "";
    if (
      this.newPasswordForm?.controls?.newPassword?.touched &&
      this.newPasswordForm?.controls?.newPassword?.errors
    ) {
      if (this.newPasswordForm?.controls?.newPassword?.errors?.required) {
        msg = "This field is required";
      } else if (
        this.newPasswordForm.controls["newPassword"].errors?.maxlength
      ) {
        msg = "Must be maximum 26 characters";
      }else if (
        this.newPasswordForm.controls["newPassword"].errors?.minlength
      ) {
        msg = "Must be minimum 6 characters";
      }
      if (this.newPasswordForm?.controls?.newPassword?.errors?.pattern) {
        msg =
          "Must be at least 8 characters and must contain 1 uppercase letter, 1 lowercase letter, 1 number and 1 special character.";
      }
    }
    return msg;
  }
}
