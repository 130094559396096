import { Component, OnInit, Output, EventEmitter, Inject } from "@angular/core";
import {
  MatDialog,
  MatDialogRef,
  MAT_DIALOG_DATA,
} from "@angular/material/dialog";
import { Subject, takeUntil } from "rxjs";

import { LocationService } from "../../services/location.service";

@Component({
  selector: "app-add-new-address-popup",
  templateUrl: "./add-new-address-popup.component.html",
  styleUrls: ["./add-new-address-popup.component.scss"],
})
export class AddNewAddressPopupComponent implements OnInit {
  isAddressSupported: boolean;
  disabled: boolean = false;
  clicked: boolean = false;
  private _unsubscribeAll: Subject<any> = new Subject<any>();

  constructor(
    public dialogRef: MatDialogRef<AddNewAddressPopupComponent>,
    private readonly locationService: LocationService,
    @Inject(MAT_DIALOG_DATA)
    public data: any
  ) {
    // should be removed and handle map comp. with input params.
    if (
      data?.addressToBeEdited?.latitude &&
      data?.addressToBeEdited?.longitude
    ) {
      let latlng = {
        latitude: data?.addressToBeEdited?.latitude,
        longitude: data?.addressToBeEdited?.longitude,
      };
      this.locationService.updateLatLng(latlng);
    }
  }

  address = {};

  @Output() closePopup: EventEmitter<boolean> = new EventEmitter<boolean>();

  ngOnInit(): void {}

  selectedAddress(event) {
    this.checkLocation(event);
  }

  checkLocation(event) {
    this.locationService
      .checkAvailableAddress(event)
      .pipe(takeUntil(this._unsubscribeAll))
      .subscribe({
        next: (res: { support: boolean; message: string }) => {
          this.isAddressSupported = res?.support;
          if (res.support) {
            this.address = event;
          }
        },
        error: (err) => {
          console.error(err);
        },
      });
  }

  saveAddress() {
    if (this.clicked) {
      return;
    }
    this.clicked = true;
    if (this.data?.edit) {
      this.editAddress();
    } else {
      this.addAddress();
    }
  }

  editAddress() {
    this.locationService
      .editAddress({ ...this.data.addressToBeEdited, ...this.address })
      .pipe(takeUntil(this._unsubscribeAll))
      .subscribe({
        next: (res) => {
          this.closePopup.emit(true);
        },
        error: (err) => {
          console.error(err);
        },
      });
  }

  addAddress() {
    this.locationService
      .addAddress({ ...this.address, valid: true })
      .pipe(takeUntil(this._unsubscribeAll))
      .subscribe({
        next: (res) => {
          this.closePopup.emit(true);
          this.clicked = false;
        },
        error: (err) => {
          this.clicked = false;
          console.error(err);
        },
      });
  }

  locationRemoved(state) {
    this.disabled = state;
  }
}
