export const CardDateMask = {
  guide: true,
  showMask: false,
  mask: [/[0-1]/, /\d/, "/", /\d/, /\d/],
};

export const CardNumberMask = {
  guide: true,
  showMask: false,
  mask: [
    /\d/,
    /\d/,
    /\d/,
    /\d/,
    " ",
    /\d/,
    /\d/,
    /\d/,
    /\d/,
    " ",
    /\d/,
    /\d/,
    /\d/,
    /\d/,
    " ",
    /\d/,
    /\d/,
    /\d/,
    /\d/,
  ],
};

export const CVCMASK = {
  guide: true,
  showMask: false,
  mask: [/\d/, /\d/, /\d/],
};

export function parseService(items): string {
  let text: Array<string> = [];

  if (!items.childs || items.childs.length == 0) {
    if (items.select) {
      if (items.type === "textarea") {
        return `"${items.value}"`;
      }

      return items.name;
    }

    return "";
  }

  for (let item of items.childs) {
    let ttt = parseService(item);

    if (ttt !== "") {
      text.push(ttt);
    }
  }

  if (text.length > 0) {
    if (items.name) {
      return items.name + ": " + text.join(", ");
    }

    return text.join(", ");
  }

  return "";
}

export function flatten(ary) {
  var ret = [];
  for (var i = 0; i < ary.length; i++) {
    if (Array.isArray(ary[i])) {
      ret = ret.concat(flatten(ary[i]));
    } else {
      ret.push(ary[i]);
    }
  }
  return ret;
}

export function scrollToElement(element: HTMLElement, offset: number = 100) {
  const elementPosition = element.getBoundingClientRect().top + window.scrollY;
  const offsetPosition = elementPosition - offset;

  window.scrollTo({
    top: offsetPosition,
    behavior: 'smooth'
  });
}

export function capitalizeFirstLetter(text) {
  // Trim the text to avoid unnecessary spaces
  text = text.trim();
  // Capitalize the first letter and add the rest of the text
  return text.charAt(0).toUpperCase() + text.slice(1);
}