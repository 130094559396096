<div class="thankyou">
    <div class="wrapper">
        <div class="dflex">
            <div class="thankyou__left dflex alignCenter justifyCenter">
                <figure>
                    <img src="/assets/images/done.svg" alt="thank you" title="thank you">
                    <figcaption>
                        <h2>Thank you for placing your order with laundrie</h2>
                        <h3>We'll see you soon!</h3>
                        <p>In meantime you can check our apps in</p>
                        <section class="dflex alignCenter justifyCenter">
                            <a target="_blank"
                                href='https://play.google.com/store/apps/details?id=ie.laundr.laundrie&hl=en&pcampaignid=MKT-Other-global-all-co-prtnr-py-PartBadge-Mar2515-1'>
                                <img src="/assets/images/googleplay.jpg" alt="google play" title="google play">
                            </a>
                            <a target="_blank" href='https://apps.apple.com/ie/app/laundrie/id998909515'>
                                <img src="/assets/images/appstore.jpg" alt="appstore" title="appstore">
                            </a>
                        </section>
                    </figcaption>
                </figure>
            </div>
            <div class="thankyou__right">
                <div class="orderItems">
                    <header>Items ordered</header>
                    <section>
                        <ul>
                            <ng-container *ngIf="orderDetails?.items?.length; else serviceTempRef">
                                <li *ngFor="let item of orderDetails?.items">
                                    <div class="dflex alignCenter justifySpaceBetween">
                                        <div class="flex alignCenter">
                                            <figure>
                                                <img [src]="item?.image ||'/assets/images/wash-fold.svg'"
                                                    [alt]="item?.name" [title]="item?.name">
                                            </figure>
                                            <figcaption>
                                                <h5>{{item?.name}}</h5>
                                            </figcaption>
                                        </div>
                                        <strong> {{item?.count}} X {{item?.price?.value}}&euro;</strong>
                                    </div>
                                </li>
                            </ng-container>
                            <ng-template #serviceTempRef>
                                <li *ngFor="let service of orderDetails?.services">
                                    <div class="dflex alignCenter justifySpaceBetween">
                                        <div class="flex alignCenter">
                                            <figure>
                                                <img src="/assets/images/wash-fold.svg" alt="Wash & fold"
                                                    title="Wash & fold">
                                            </figure>
                                            <figcaption>
                                                {{ parseService(service) }}
                                            </figcaption>
                                        </div>
                                    </div>
                                </li>
                            </ng-template>
                        </ul>
                        <div class="flex alignCenter justifySpaceBetween discount" *ngIf="orderDetails?.items?.length">
                            <span>Shipping</span>
                            <strong><span *ngIf="getShippingCost; then shipping else shippingFree"></span></strong>
                        </div>
                        <ng-template #shipping>{{ getShippingCost | number:'1.2-2' }} &euro;</ng-template>
                        <ng-template #shippingFree>free!</ng-template>
                        <div *ngIf="orderDetails?.coupon?.coupon_code" class="flex justifySpaceBetween discount">
                            <span>Net Amount</span>
                            <strong>{{ (orderDetails?.net_amount) || 0 | number:'1.2-2'}} €</strong>
                        </div>
                        <div *ngIf="orderDetails?.coupon?.coupon_code" class="flex justifySpaceBetween discount">
                            <span>Coupon Discount</span>
                            <strong>{{ getDiscountValue || 0 | number:'1.0-2'}} €</strong>
                        </div>
                    </section>
                    <footer class="flex justifySpaceBetween">
                        <span>Total payment </span>
                        <span>{{orderDetails?.total_amount}} &euro;</span>
                    </footer>
                </div>

                <div class="orderItems">
                    <header>Pickup and delivery address</header>
                    <section>
                        <div class="dflex justifySpaceBetween">
                            <div class="deliverAddress">
                                <h5>Pickup address</h5>
                                <p>{{orderDetails?.pickup_address?.address}}</p>
                            </div>
                            <div class="deliverAddress">
                                <h5>Delivery address</h5>
                                <p>{{orderDetails?.delivery_address?.address}}</p>
                            </div>
                        </div>
                    </section>
                </div>
                <div class="orderItems">
                    <header>Date and time</header>
                    <section>
                        <div class="dflex justifySpaceBetween">
                            <div class="deliverAddress">
                                <h5>Pickup date times</h5>
                                <p>{{orderDetails?.pickup_time | timeDate | date}}</p>
                                <p>{{orderDetails?.pickup_time | timeDate | date:'h a'}} -
                                    {{orderDetails?.pickup_time_end | timeDate | date:'h a'}}</p>
                            </div>
                            <div class="deliverAddress">
                                <h5>Delivery date times</h5>
                                <p>{{orderDetails?.delivery_time | timeDate | date}}</p>
                                <p>{{orderDetails?.delivery_time | timeDate | date :'h a'}} -
                                    {{orderDetails?.delivery_time_end | timeDate | date:'h a'}}</p>
                            </div>
                        </div>
                    </section>
                </div>
                <div class="orderItems">
                    <header class="flex justifySpaceBetween">
                        <span>Payment method</span>
                        <span class="flex alignCenter cardType"><img src="/assets/images/master-card.png"
                                alt="master-card" title="master-card"> XXXX {{orderDetails?.card?.last4}}</span>
                    </header>
                </div>
            </div>
        </div>
    </div>
</div>