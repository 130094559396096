import { NgModule } from "@angular/core";
import { CommonModule } from "@angular/common";
import { NgxSkeletonLoaderModule } from "ngx-skeleton-loader";
import { SkeltonsComponent } from "./skeletons.component";

@NgModule({
  declarations: [SkeltonsComponent],
  imports: [CommonModule, NgxSkeletonLoaderModule],
  exports: [SkeltonsComponent],
})
export class SkeltonsModule {}
