import { Injectable } from "@angular/core";
import { BehaviorSubject, Subject, map } from "rxjs";
import { APIS } from "../../constants/api.contants";
import { ProductAdaptor } from "../../order-now/adaptors/products.adaptor";
import { ICategory } from "../../pricing/models/category.models";
import { HttpService } from "../../services/http.service";

export const DESCRIPTION_CONFIG = {
  mixed:
    "Light & Dark Clothes will be washed at 40 degrees. Minimum order is 6kg costing €19.80 each additional kg is charged at €3.30",
  separate:
    "Light & Dark Clothes will be washed separately at 40 degrees. Minimum order is 12kg costing €39.60 each additional kg is charged at €3.30",
};

@Injectable({
  providedIn: "root",
})
export class SharedService {
  categories: BehaviorSubject<ICategory[]> = new BehaviorSubject<any>([]);
  settings: BehaviorSubject<any> = new BehaviorSubject<any>({});
  addressUpdated: Subject<any> = new Subject<any>();
  constructor(
    private _httpService: HttpService,
    private productAdaptor: ProductAdaptor
  ) {}

  fetchCatList() {
    return this._httpService
      .callGetApi(APIS.category.cat, { all: true })
      .pipe(map((data) => this.productAdaptor.adapt(data)));
  }

  fetchSettings() {
    this._httpService.callGetApi(APIS.settings.setting).subscribe({
      next: (res: any) => {
        let services = res.services.map((s) => {
          let childs = s.childs.map((c) => {
            return {
              ...c,
              description: DESCRIPTION_CONFIG?.[c.slug] || c?.description,
            };
          });
          return {
            ...s,
            childs,
          };
        });
        res = {
          ...res,
          services,
        };
        this.settings.next(res);
      },
      error: (err) => {
        console.error(err);
      },
    });
  }

  fetchCategories() {
    this.fetchCatList().subscribe({
      next: (result: ICategory[]) => {
        this.categories.next(result);
      },
      error: (error: any) => {
        console.error(error);
      },
    });
  }
}
