export const APIS = {
  auth: {
    login: `/users/login`,
    register: `/public/users/register`,
    resetPassword: "/public/users/reset_password",
    changePassword: "/public/users/change_password",
  },
  user: {
    addresses: "/users/me/addresses",
    orders: "/users/me/orders",
    createOrder: "/users/me/orders/add",
    cards: "/users/me/cards",
    saveCard: "/users/me/cards/add",
    me: "/users/me",
  },
  coupon: {
    validate: "/coupons",
  },
  category: {
    cat: "/public/categories",
  },
  availability: {
    check: "/public/availability/check",
    time: "/public/availability/times",
    slotsBasedOnAddressId:
      "addresses/1e15f73721d43935af9dd70fef890e51/availability",
  },
  address: {
    add: "/users/me/addresses/add",
    edit: "/users/me/addresses/",
  },
  settings: {
    setting: "/public/settings",
  },
  posts: {
    fetch: "/wp/v2/posts/",
  },
  postImages: {
    img: "/wp/v2/media/",
  },
};
