<footer [ngClass]="[classHide]">
    <!-- dotted-lines -->
    <div class="dotted-lines">
        <img src="/assets/images/dotted.svg" alt="Dots">
    </div>
    <!-- people-trust -->
    <div class="peopleTrust">
        <div class="wrapper flex alignCenter justifySpaceBetween">
            <div class="peopleTrust__left">
                <h4>People trust us</h4>
                <p>Check our review on Trustpilot</p>
            </div>
            <div class="peopleTrust__right">
                <a class="flex" href="https://www.trustpilot.com/review/www.laundr.ie" target="_blank">
                    <img src="/assets/images/trustpilot.svg" alt="Trustpilot" title="Trustpilot">
                </a>
            </div>
        </div>
    </div>
    <!-- footer-content -->
    <div class="footer">
        <div class="wrapper dflex alignStart justifySpaceBetween">
            <div class="footer__col firstcol">
                <app-logo></app-logo>
                <p>
                    We launched in August 2015 and have been keeping the people of Dublin in clean cloths since. We
                    offer
                    a same day pick-up laundry delivery service, city-wide in Dublin, Ireland.
                </p>
            </div>
            <div class="footer__col">
                <h6>
                    Company
                </h6>
                <ul>
                    <li>
                        <a [routerLinkActive]="'active'" [routerLink]="['/about']">About</a>
                    </li>
                    <li>
                        <a [routerLink]="['/app']" [routerLinkActive]="'active'">Order now</a>
                    </li>
                    <li>
                        <a [routerLinkActive]="'active'" [routerLink]="['/pricing']">Pricing & Services</a>
                    </li>
                    <li>
                        <a [routerLinkActive]="'active'" [routerLink]="['/faq']">FAQ</a>
                    </li>
                    <li class="mobile">
                        <a [routerLinkActive]="'active'" routerLink="/privacy">
                            Privacy policy
                        </a>
                    </li>
                    <li class="mobile">
                        <a [routerLinkActive]="'active'" [routerLink]="['/terms']">
                            Terms and conditions
                        </a>
                    </li>
                </ul>
            </div>
            <div class="footer__col">
                <div class="footer__col-address">
                    <h6>
                        Contact Info
                    </h6>
                    <p>115 Cork Street <br /> Dublin 8</p>
                    <p>
                        <a href="tel:+353016719433" target="_blank">
                            +353 (0)1 6719433
                        </a>
                        <a href="mailto:help@laundr.ie" target="_blank">
                            help@laundr.ie
                        </a>
                    </p>
                    <p>Monday-Sunday <br /> 09.00-22.00</p>
                </div>
            </div>
        </div>
    </div>
    <!-- footer-copyright -->
    <div class="copyright">
        <div class="wrapper flex alignCenter justifySpaceBetween">
            <div class="copyright__left flex alignCenter">
                <p>
                    &#169; 2022 Laundrie. All Rights Reserved.
                </p>
                <ul class="flex alignCenter">
                    <li>
                        <a [routerLinkActive]="'active'" routerLink="/privacy">
                            Privacy policy
                        </a>
                    </li>
                    <li>|</li>
                    <li>
                        <a [routerLinkActive]="'active'" [routerLink]="['/terms']">
                            Terms and conditions
                        </a>
                    </li>
                </ul>
            </div>
            <ul class="copyright__right flex alignCenter">
                <li>
                    <a href="https://www.instagram.com/laundriedublin/" target="_blank">
                        <img src="/assets/images/instagram.svg" alt="instagram" title="instagram">
                    </a>
                </li>
                <li>
                    <a href="https://www.facebook.com/laundriedublin/?fref=ts" target="_blank">
                        <img src="/assets/images/facebook.svg" alt="facebook" title="facebook">
                    </a>
                </li>
                <li>
                    <a href="https://twitter.com/laundriedublin" target="_blank">
                        <img src="/assets/images/twitter.svg" alt="twitter" title="twitter">
                    </a>
                </li>
            </ul>
        </div>
    </div>
</footer>