import { Component, NgZone, OnDestroy, OnInit } from "@angular/core";
import { LocationService } from "../../services/location.service";

@Component({
  selector: "app-map",
  templateUrl: "./map.component.html",
  styleUrls: ["./map.component.scss"],
})
export class MapComponent implements OnInit, OnDestroy {
  markerPosition: { latitude: number; longitude: number } = {
    latitude: 53.3318105,
    longitude: -6.2610551,
  };

  mapCenter: { zoom: number; latitude: number; longitude: number } = {
    zoom: 14,
    latitude: 53.3318105,
    longitude: -6.2610551,
  };
  map: any;
  mapClickListener: any;

  address: any = {};

  constructor(private locationService: LocationService, private zone: NgZone) {}

  ngOnInit(): void {
    this.locationService.mapCenterSubject.subscribe({
      next: (data) => {
        if (data) {
          this.mapCenter = {
            ...this.mapCenter,
            ...data,
          };
        }
      },
    });
    this.locationService.markerPositionSubject.subscribe({
      next: (data) => {
        if (data) {
          this.markerPosition = { ...data };
        }
      },
    });
  }

  saveCords(latLng): void {
    this.markerPosition.latitude = this.address.latitude = latLng.lat;
    this.markerPosition.longitude = this.address.longitude = latLng.lng;
  }

  setMapCenter(latLng): void {
    this.mapCenter.latitude = latLng.lat;
    this.mapCenter.longitude = latLng.lng;
  }

  // selectLocation(event: { coords: { lat: number; lng: number } }): void {
  //   console.log(event);
  //   if (event.coords) {
  //     // this.moveMarker(event?.coords)
  //   }
  // }

  moveMarker(latLng): void {
    this.saveCords(latLng);
    this.locationService.reverseGeocode(latLng.lat, latLng.lng, 50).subscribe({
      next: (res) => {
        console.log(res);
      },
    });
  }

  public mapReadyHandler(map: google.maps.Map): void {
    this.map = map;
    this.mapClickListener = this.map.addListener(
      "click",
      (e: google.maps.MouseEvent) => {
        this.zone.run(() => {
          // Here we can get correct event
          const coords = { lat: e.latLng.lat(), lng: e.latLng.lng() };
          console.log(e, coords);
          this.moveMarker(coords);
        });
      }
    );
  }

  ngOnDestroy(): void {
    if (this.mapClickListener) {
      this.mapClickListener.remove();
    }
  }
}
