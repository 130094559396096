import {
  HttpClient,
  HttpEventType,
  HttpParams,
  HttpRequest,
} from "@angular/common/http";
import { Injectable } from "@angular/core";
import { Router } from "@angular/router";
import { Observable, Observer, publishReplay, refCount, tap } from "rxjs";
import { environment } from "../../environments/environment";
import { SnackbarService } from "../atoms/components/snackbar/snackbar.service";

@Injectable({
  providedIn: "root",
})
export class HttpService {
  constructor(
    private http: HttpClient,
    private router: Router,
    private _snackbarService: SnackbarService
  ) {}

  observer: Observer<any>;

  handleResponse(response) {
    return response;
  }

  handleErrorResponse(error, url?: string) {
    if (error?.error) {
      if (Array.isArray(error?.error)) {
        this._snackbarService.openSnackBar(
          error.error[0].message,
          "error",
          5000
        );
      } else if (error?.error?.message) {
        if (Array.isArray(error?.error?.message)) {
          this._snackbarService.openSnackBar(
            error.error.message[0].message,
            "error",
            5000
          );
        }
        // Server error
        else {
          this._snackbarService.openSnackBar(
            error.error.message,
            "error",
            5000
          );
        }
      }
    }

    if (error.status === 500 || !error.status) {
      this._snackbarService.openSnackBar(
        "Something went wrong, please try again later.",
        "error",
        5000
      );
    }
    if (error && error.status === 401 && !url?.includes("change_password")) {
      this.router.navigate(["/login"], { skipLocationChange: true });
    } else {
      return error;
    }
  }

  /**
   * GET
   * @param apiurl
   */
  callGetApi(apiurl: string, params: any = {}, version: string = "v1") {
    // const sessionKey = this.dataProviderService.getSessionKey();
    const url = this.getBaseUrl(apiurl, version);
    let { contentType } = params;
    return this.http
      .get(url, {
        headers: {
          // sessionKey,
          "Content-Type": contentType || "application/json",
        },
        params: params,
      })
      .pipe(
        tap({
          next: (data) => this.handleResponse(data),
          error: (error) => this.handleErrorResponse(error, apiurl),
        })
      );
  }

  /**
   * POST
   * @param apiurl
   * @param body
   * @param options
   */
  callPostApi(apiurl: string, body: any, params: any = {}, options?) {
    // // const sessionKey = this.dataProviderService.getSessionKey();
    let { contentType } = params;
    const url = this.getBaseUrl(apiurl);
    return this.http
      .post(url, body, {
        params,
        headers: {
          "Content-Type": contentType || "application/json",
          // sessionKey,
        },
      })
      .pipe(
        tap({
          next: (data) => this.handleResponse(data),
          error: (error) => {
            if (
              typeof options == "undefined" ||
              options.handleError == null ||
              options.handleError === true
            )
              this.handleErrorResponse(error, apiurl);
          },
        })
      );
  }

  /**
   * POST
   * @param apiurl
   * @param body
   * @param options
   */
  callPostApiWithMultiPartFormData(
    apiurl: string,
    body: any,
    options: any = {}
  ) {
    // const session_key = this.dataProviderService.getSessionKey();
    let { contentType } = options;
    const url = this.getBaseUrl(apiurl);
    return this.http
      .post(url, body, {
        headers: {
          contentType: contentType || "application/x-www-form-urlencoded",
          // session_key,
        },
      })
      .pipe(
        tap({
          next: (data) => this.handleResponse(data),
          error: (error) => this.handleErrorResponse(error, apiurl),
        })
      );
  }

  /**
   * POST(with progress)
   * @param apiurl
   * @param body
   * @param options
   */
  callPostApiWithProgress(apiurl: string, body: any, options?) {
    const url = this.getBaseUrl(apiurl);
    return Observable.create((observer: Observer<any>) => {
      this.http
        .request(
          new HttpRequest("POST", url, body, {
            reportProgress: true,
          })
        )
        .subscribe((event) => {
          if (event.type === HttpEventType.DownloadProgress) {
          }
          if (event.type === HttpEventType.UploadProgress) {
          }
          if (event.type === HttpEventType.Response) {
            observer.next(event.body);
          }
        });
    });
  }

  /**
   * PUT
   * @param apiurl
   * @param body
   * @param params
   */
  callPutApi(apiurl: string, body: any, params?: HttpParams) {
    let { contentType } = body;
    const url = this.getBaseUrl(apiurl);
    return this.http
      .put(url, body, {
        headers: {
          "Content-Type": contentType || "application/json",
          // origin: "https://app.laundr.ie",
          // referer: "https://app.laundr.ie",
        },
      })
      .pipe(
        tap({
          next: (data) => this.handleResponse(data),
          error: (error) => this.handleErrorResponse(error, apiurl),
        })
      );
  }

  callPutApiWithoutHeader(
    apiurl: string,
    body: any,
    params?: HttpParams | any
  ) {
    let contentType = params;
    const url = this.getBaseUrl(apiurl);
    return this.http
      .put(url, body, {
        headers: {
          "Content-Type": contentType,
        },
      })
      .pipe(
        tap({
          next: (data) => this.handleResponse(data),
          error: (error) => this.handleErrorResponse(error, apiurl),
        })
      );
  }

  /**
   * DELETE
   * @param apiurl
   * @param params
   */
  callDeleteApi(apiurl: string, params = {}) {
    // // const sessionKey = this.dataProviderService.getSessionKey();
    const url = this.getBaseUrl(apiurl);
    return this.http
      .delete(url, {
        headers: {
          // sessionKey,
        },
        params,
      })
      .pipe(
        tap({
          next: (data) => this.handleResponse(data),
          error: (error) => this.handleErrorResponse(error, apiurl),
        })
      );
  }

  callDeleteApiWithBody(apiurl: string, body = {}, params = {}) {
    // const session_key = this.dataProviderService.getSessionKey();
    const url = this.getBaseUrl(apiurl);
    return this.http
      .request("DELETE", url, {
        headers: {
          // session_key,
        },
        params,
        body,
      })
      .pipe(
        tap({
          next: (data) => this.handleResponse(data),
          error: (error) => this.handleErrorResponse(error, apiurl),
        })
      );
  }

  callCustomRequest(
    path: string,
    params: {} = {},
    data: {} = {},
    method: string = "JSONP"
  ): Observable<any> {
    const headers = { "Content-Type": "application/json" };
    params["key"] = environment.AUTO_ADDRESS_KEY;
    params["callback"] = "JSONP_CALLBACK";

    return this.http
      .request(method, this.getBaseUrl(path, "v2"), {
        body: data,
        params: params,
      })
      .pipe(publishReplay())
      .pipe(refCount()); //;.pipe(share());//.toPromise();
  }

  getHTTPParams(object): HttpParams {
    let params = new HttpParams();

    for (let p in object) {
      params.append(p, object[p]);
    }

    return params;
  }

  callPutApiForChangePassword(
    apiurl: string,
    body: any,
    contentType: string,
    accept: string
  ) {
    const url = this.getBaseUrl(apiurl);
    return this.http
      .put(url, body, {
        headers: {
          "Content-Type": contentType,
          accept: accept,
          // origin: "https://app.laundr.ie",
          // referer: "https://app.laundr.ie",
        },
      })
      .pipe(
        tap({
          next: (data) => this.handleResponse(data),
          error: (error) => this.handleErrorResponse(error, apiurl),
        })
      );
  }

  getBaseUrl(path: string, version: string = "v1") {
    let BASE_URL = environment.API_URL || "https://api-staging.laundr.ie/v1";
    // uncomment and handle if has multiple baseurl as per environments
    switch (version) {
      case "v1":
        BASE_URL = environment.API_URL || "https://api-staging.laundr.ie/v1";
        break;
      case "v2":
        BASE_URL = "https://api.autoaddress.ie/2.0/";
        break;
      case "v3":
        BASE_URL = "https://blog.app.laundr.ie/wp-json";
        break;
      default:
        BASE_URL = "https://api-staging.laundr.ie/v1";
        break;
    }
    return `${BASE_URL}${path}`;
  }
}
