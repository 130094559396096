import { NgModule } from "@angular/core";
import { CommonModule } from "@angular/common";
import { FormsModule, ReactiveFormsModule } from "@angular/forms";
import { OrganismsModule } from "../organisms/organisms.module";
import { MaterialModule } from "./material/material.module";
import { LogoComponent } from "./components/logo/logo.component";
import { HeaderComponent } from "./components/header/header.component";
import { FooterComponent } from "./components/footer/footer.component";
import { DigitOnlyDirective } from "./directives/digit-only.directive";
import { RouterModule } from "@angular/router";
import { SlickCarouselModule } from "ngx-slick-carousel";
import { SkeltonsModule } from "./skeletons/skeltons.module";
import { AddressBarComponent } from "./components/address-bar/address-bar.component";
import { RectangleComponent } from "./components/rectangle/rectangle.component";
import { AgmCoreModule } from "@agm/core";
import { environment } from "../../environments/environment";
import { MapComponent } from "./components/map/map.component";
import { NiceDatePipe } from "./pipes/nice-date/nice-date.pipe";
import { ConfirmationDialogComponent } from "./components/confirmation-dialog/confirmation-dialog.component";
import { ThankyouPageComponent } from "./components/thankyou-page/thankyou-page.component";
import { FieldAlertComponent } from "../molecules/components/field-alert/field-alert.component";
import { AddressCardComponent } from "./components/address-card/address-card.component";
import { AddNewAddressPopupComponent } from "./components/add-new-address-popup/add-new-address-popup.component";
import { ClickOutsideDirective } from "./directives/click-outside.directive";
import { MobileBannerComponent } from "./components/mobile-banner/mobile-banner.component";
import { LoginComponent } from "../auth/components/login/login.component";
import { Error404PageComponent } from "./components/404/error-404-page.component";
import { EscapeHtmlPipe } from "./pipes/keep-html/keep-html.pipe";
import { PastDatePipe } from "./pipes/past-date/past-date.pipe";
import { TimeDatePipe } from "./pipes/time-date.pipe";

const MODULES = [
  ReactiveFormsModule,
  FormsModule,
  OrganismsModule,
  MaterialModule,
  RouterModule,
  SlickCarouselModule,
  SkeltonsModule,
];

const PIPES = [NiceDatePipe, EscapeHtmlPipe, PastDatePipe, TimeDatePipe];

const COMPONENTS = [
  LogoComponent,
  HeaderComponent,
  FooterComponent,
  AddressBarComponent,
  MapComponent,
  RectangleComponent,
  ConfirmationDialogComponent,
  ThankyouPageComponent,
  FieldAlertComponent,
  AddressCardComponent,
  AddNewAddressPopupComponent,
  MobileBannerComponent,
  LoginComponent,
  Error404PageComponent,
];

const DIRECTIVES = [DigitOnlyDirective, ClickOutsideDirective];

@NgModule({
  declarations: [...COMPONENTS, ...PIPES, ...DIRECTIVES],
  imports: [
    CommonModule,
    AgmCoreModule.forRoot({
      apiKey: environment.GOOGLE_MAP_KEY,
    }),
    ...MODULES,
  ],
  exports: [...MODULES, ...COMPONENTS, ...DIRECTIVES, ...PIPES],
})
export class SharedModule {}
