import {Component, TemplateRef, Output, EventEmitter, Input, ViewEncapsulation} from '@angular/core';
import {FormGroup} from "@angular/forms";

@Component({
    selector: 'app-field-alert',
    templateUrl: './field-alert.component.html',
    styleUrls: [
        './styles/field-alert.styles.scss'
    ],
    encapsulation: ViewEncapsulation.None
})
export class FieldAlertComponent {
    @Input()
    field: FormGroup;

    @Input()
    form: FormGroup;

    @Output('action') action: EventEmitter<any> = new EventEmitter();
    constructor() { }
    emit(action){
        this.action.emit(action)
    }
}
