<div class="authform">
    <h3 *ngIf="hasLoginPage">Welcome to Login</h3>
    <form *ngIf="loginForm" [formGroup]="loginForm">
        <div class="authform__field">
            <mat-form-field>
                <mat-label>Email</mat-label>
                <input matInput formControlName="email">
            </mat-form-field>
            <mat-error *ngIf="loginForm.controls.email.touched && loginForm.controls.email.errors">
                {{ emailErrorMessage }}</mat-error>
        </div>
        <div class="authform__field">
            <mat-form-field>
                <mat-label>Password</mat-label>
                <input matInput [type]="displayPassword ? 'text' : 'password'" formControlName="password" />
                <span matsuffix class="eyeicon" (click)="showPassword()">
                    <img [src]="displayPassword ? '/assets/images/eye-crossed.svg' : '/assets/images/eye.svg'"
                        class="Eye" alt="eye">
                </span>
            </mat-form-field>
            <mat-error *ngIf="loginForm.controls.password.touched && loginForm.controls.password.errors">
                {{ passwordErrorMessage }}
            </mat-error>
        </div>
        <div class="authform__text dflex alignEnd justifyEnd" *ngIf="hasLoginPage">
            <a [routerLink]="['/auth/forgot-password']">Forgot password?</a>
        </div>
        <div class="authform__btn">
            <app-button [label]="'Login'" [showLoader]="clicked" [isDisabled]="loginForm.invalid || clicked"
                (action)="submitLoginForm()"></app-button>
        </div>
    </form>
    <div class="authform__bottom" *ngIf="hasLoginPage">
        Don't have an Account? <a [routerLink]="['/auth/register']"> Sign up</a>
    </div>
</div>