import { Injectable } from "@angular/core";
import { APIS } from "../../constants/api.contants";
import { HttpService } from "../../services/http.service";
import {
  IloginForm,
  ISignupPayload,
  IChangePasswordPayload,
} from "../models/auth.models";
@Injectable({
  providedIn: "root",
})
export class AuthService {
  constructor(private _httpService: HttpService) {}

  login(payload: IloginForm) {
    return this._httpService.callPostApi(APIS.auth.login, payload);
  }

  signup(payload: ISignupPayload) {
    return this._httpService.callPostApi(APIS.auth.register, payload);
  }

  resetPassword(email: string) {
    return this._httpService.callPostApi(APIS.auth.resetPassword, { email });
  }

  changePassword(payload: any) {
    const body = new URLSearchParams({
      password: payload.password,
      token: payload.token,
    });
    const contentType = "application/x-www-form-urlencoded";
    const accept = "application/json";
    return this._httpService.callPutApiForChangePassword(
      APIS.auth.changePassword,
      body,
      contentType,
      accept
    );
  }
}
