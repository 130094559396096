<app-header></app-header>
<div class="notFound dflex justifyCenter alignCenter">
    <div class="wrapper">
        <div class="notFound__inner">
            <img src="/assets/images/not-found-404.svg" alt="Page not found" title="Page not found">
            <h1>Page not found</h1>
            <p>We're sorry the page you requested could not be found, please go back to the homepage.</p>
            <app-button [routerLink]="['/']" [label]="'GO TO HOME'"></app-button>
        </div>
    </div>
</div>