import { Component, EventEmitter, Input, OnInit, Output } from "@angular/core";

@Component({
  selector: "app-button",
  templateUrl: "./button.component.html",
  styleUrls: ["./button.component.scss"],
})
export class ButtonComponent implements OnInit {
  @Input() type:
    | "primary"
    | "secondary"
    | "white"
    | "cancel"
    | "outline"
    | "orange"
    | "google" = "primary";
  @Input() class:
    | "sm"
    | "md"
    | "lg"
    | "secondary"
    | "white"
    | "full"
    | "outline"
    | "google"
    | "" = "";
  @Input() mode: "dark" | "light" = "light";
  @Input() shape: "rounded" | "flate" = "flate";
  @Input() label!: string;
  @Input() isDisabled: boolean | string = false;
  @Input() showLoader: boolean = false;
  @Input() tooltip!: string;
  @Output() action: EventEmitter<any> = new EventEmitter();

  constructor() {}

  ngOnInit(): void {}
}
