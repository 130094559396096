import { Routes } from "@angular/router";
import { NewPasswordComponent } from "./auth/components/new-password/new-password.component";
import { LoginGuard } from "./guards/login.guard";
import { Error404PageComponent } from "./shared/components/404/error-404-page.component";

export const rootRoutes: Routes = [
  {
    path: "auth",
    canActivate: [LoginGuard],
    loadChildren: () => import("./auth/auth.module").then((x) => x.AuthModule),
  },
  {
    path: "",
    loadChildren: () =>
      import("./pages/pages.module").then((x) => x.PagesModule),
  },
  // TBD move to auth flow when BE is done
  {
    path: "change-password/:slug",
    component: NewPasswordComponent,
  },
  {
    path: "404",
    component: Error404PageComponent,
  },
  // There's a bug that's preventing wild card routes to be lazy loaded (see: https://github.com/angular/angular/issues/13848)
  // That's why the Error page should be eagerly loaded
  {
    path: "**",
    component: Error404PageComponent,
  },
];
