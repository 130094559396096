import { Component, OnDestroy, OnInit } from "@angular/core";
import { ActivationStart, NavigationEnd, Router } from "@angular/router";
import { Subject, takeUntil } from "rxjs";

@Component({
  selector: "app-footer",
  templateUrl: "./footer.component.html",
  styleUrls: ["./footer.component.scss"],
})
export class FooterComponent implements OnInit, OnDestroy {
  private _unsubscribeAll: Subject<void> = new Subject<void>();
  className: string = "";
  classHide: string = "";

  constructor(private router: Router) {}

  ngOnInit(): void {
    this.subscribeRoutes();
  }

  subscribeRoutes() {
    let url = this.router.url;
    if (url === "/faq") {
      this.className = "bggrey";
    }
    if (url === "/pricing") {
      this.className = "bggrey";
    }
    if (url === "/app") {
        this.classHide = "hideMobile";
      }
    this.router.events
      .pipe(takeUntil(this._unsubscribeAll))
      .subscribe((event) => {
        if (event instanceof ActivationStart) {
          this.className = event.snapshot.data?.className || "";
          this.classHide = event.snapshot.data?.classHide || "";
        }
      });
  }

  /**
   * On destroy
   */
  ngOnDestroy(): void {
    // Unsubscribe from all subscriptions
    this._unsubscribeAll.next();
    this._unsubscribeAll.complete();
  }
}
