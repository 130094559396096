<div *ngIf="visible" class="mobileBanner">
    <div class="dotted-background big-dots"></div>
    <div class="dotted-background small-dots"></div>
    <div class="flex alignCenter mobileBanner__content">
        <button class="mobileBanner__content-close" (click)="close()">
            <img src="assets/images/close-grey.svg" alt="Close">
        </button>
        <div class="mobileBanner__content-logo">
            <img src="assets/images/ic_launcher.png" alt="Laundrie">
        </div>
        <div class="mobileBanner__content-star">
            <p>
                Laundrie for {{data.os}}
            </p>
            <div class="mobileBanner__starGroup flex alignCenter">
                <span><img src="../../../../assets/images/star.svg" alt=""></span>
                <span><img src="../../../../assets/images/star.svg" alt=""></span>
                <span><img src="../../../../assets/images/star.svg" alt=""></span>
                <span><img src="../../../../assets/images/star.svg" alt=""></span>
                <span><img src="../../../../assets/images/starh.svg" alt=""></span>
                <span>
                    <span>({{data.number}})</span>
                </span>
            </div>
        </div>
        <div class="mobileBanner__content-btn">
            <a href="{{data.url}}" rel="nofollow"> Install </a>
        </div>
    </div>
</div>