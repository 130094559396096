import { Component, Input, ViewEncapsulation } from '@angular/core';

@Component({
    selector: 'app-rectangle',
    templateUrl: './rectangle.component.html',
    styleUrls: [
        './styles/rectangle.styles.scss'
    ],
    encapsulation: ViewEncapsulation.None
})
export class RectangleComponent {
    _ratio: { w: number, h: number };

    @Input() set ratio(ratio: { w: number, h: number }) {
        this._ratio = ratio || {w: 1, h: 1};
    }
}

