import { Injectable } from "@angular/core";
import { FormBuilder, FormGroup, Validators } from "@angular/forms";
import { BehaviorSubject, map, Subject } from "rxjs";
import { APIS } from "../../constants/api.contants";
import { HttpService } from "../../services/http.service";
import {
  noWhitespaceValidator,
  RegularExpressionConstant,
} from "../../validators/validator";
import { AvailableSlotsAdapter } from "../adaptors/availableSlots.adapter";
import { IStepDetails } from "../models/steps.models";

export const DefaultCheckoutData = {
  pickupAddress: null,
  deliveryAddress: null,
  sameAsPickup: true,
};

export const DefaultSlotsData = {
  pickupSlotDate: null,
  pickupSlotTime: null,
  deliverySlotDate: null,
  deliverySlotTime: null,
};

@Injectable({
  providedIn: "root",
})
export class CheckoutService {
  checkoutFormGroup: FormGroup;
  showService: BehaviorSubject<boolean> = new BehaviorSubject(true);
  checkoutData: BehaviorSubject<any> = new BehaviorSubject(DefaultCheckoutData);
  slotsData: BehaviorSubject<any> = new BehaviorSubject(DefaultSlotsData);
  catalogue: BehaviorSubject<any> = new BehaviorSubject(null);
  paymentDetails: BehaviorSubject<any> = new BehaviorSubject(null);
  contactDetails: BehaviorSubject<any> = new BehaviorSubject(null);
  appliedPromo: BehaviorSubject<any> = new BehaviorSubject(null);
  resetCatalogueData: Subject<any> = new Subject();
  readonly orderSummarySubject$: BehaviorSubject<IStepDetails> =
    new BehaviorSubject<IStepDetails>({});

  constructor(
    private httpService: HttpService,
    private availableSlotsAdapter: AvailableSlotsAdapter,
    private _fb: FormBuilder
  ) {
    this.initCheckoutForm();
  }

  initCheckoutForm() {
    this.checkoutFormGroup = this._fb.group({
      contactDetailsFormGroup: this._fb.group({
        first_name: ["", [Validators.required, noWhitespaceValidator]],
        last_name: ["", [Validators.required, noWhitespaceValidator]],
        email: [
          "",
          [
            Validators.required,
            Validators.pattern(RegularExpressionConstant?.EMAIL),
          ],
        ],
        phone: [
          "",
          [
            Validators.required,
            noWhitespaceValidator,
            Validators.minLength(9),
            // Validators.maxLength(10),
          ],
        ],
        password: ["", [Validators.required, Validators.minLength(6)]],
      }),
      paymentFormGroup: this._fb.group({
        cvc: ["", [Validators.required]],
        exp: ["", [Validators.required]],
        exp_month: ["", [Validators.required]],
        exp_year: ["", [Validators.required]],
        name: ["", [Validators.required]],
        number: ["", [Validators.required]],
      }),
    });
  }

  resetOrderSummary() {
    this.checkoutFormGroup.reset();
    this.appliedPromo.next(null);
    this.slotsData.next(DefaultSlotsData);
    this.checkoutData.next(DefaultCheckoutData);
    this.paymentDetails.next(null);
    this.contactDetails.next(null);
    this.orderSummarySubject$.next({});
  }

  resetCatalogue() {
    this.catalogue.next(null);
    this.resetCatalogueData.next({});
  }

  availableTimeSlots(payload) {
    return this.httpService
      .callPostApi(APIS.availability.time, payload)
      .pipe(map((data) => this.availableSlotsAdapter.adapt(data)));
  }

  fetchTimeSlotsBasedOnAddressId(addressId) {
    const url = `${APIS.user.addresses}/${addressId}/availability?addressId=${addressId}`;
    return this.httpService
      .callGetApi(url)
      .pipe(map((data) => this.availableSlotsAdapter.adapt(data)));
  }

  createOrder(payload) {
    return this.httpService.callPostApi(APIS.user.createOrder, payload);
  }

  fetchOrderDetails(id: string) {
    return this.httpService.callGetApi(`${APIS.user.orders}/${id}`);
  }

  validateCoupon(coupon: string) {
    return this.httpService.callGetApi(
      `${APIS.coupon.validate}/${coupon}/validate`
    );
  }

  updateSlots(value) {
    let previousVal = this.slotsData.value;
    this.slotsData.next({
      ...previousVal,
      ...value,
    });
  }

  resetSlotDetails() {
    this.slotsData.next(DefaultSlotsData);
  }

  updateCheckoutData(value) {
    let previousVal = this.checkoutData.value;
    this.checkoutData.next({
      ...previousVal,
      ...value,
    });
  }

  resetCheckoutDetails() {
    this.checkoutData.next(DefaultCheckoutData);
  }

  removeCatalogue(stepName: string) {
    let val = JSON.parse(JSON.stringify(this.catalogue?.value || {}));
    delete val?.[stepName];
    this.catalogue.next(val);
  }

  updateCatalogue(value: any) {
    let val = JSON.parse(JSON.stringify(this.catalogue.value));
    this.catalogue.next({ ...val, ...value });
  }
}
