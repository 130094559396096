// The file contents for the current environment will overwrite these during build.
// The build system defaults to the dev environment which uses `environment.ts`, but if you do
// `ng build --env=prod` then `environment.prod.ts` will be used instead.
// The list of which env maps to which file can be found in `.angular-cli.json`.

export const environment = {
  production: false,
  BASE_URL: "https://app-staging.laundr.ie",
  API_URL: "https://api-staging.laundr.ie/v1",
  AUTO_ADDRESS_KEY: "C86A5016-0963-ADC0-C598-1BFEB159916E",
  GOOGLE_MAP_KEY: "AIzaSyBDZPtOwX_Cpfn6BdA01_3uvISUD05eyyU",
  GTM_ID: "GTM-5XK8B7H",
};
