import {Inject, LOCALE_ID, Pipe, PipeTransform} from '@angular/core';
import {DatePipe, formatDate} from "@angular/common";

@Pipe({ name: 'niceDate' })
export class NiceDatePipe implements PipeTransform{

    constructor(@Inject(LOCALE_ID) private locale: string) {}

    transform(
        date: Date,
        defaultFormat: string = 'dd MMMM',
        closeDays: Array<{ format: string, num: number }> = [{format: "EEEE", num: 6 },{format: "'next' EEEE", num: 13 }],
        days: Array<string> = ['today', 'tomorrow', 'day after tomorrow']
    ) : string{
        const oneDay: number = 86400000;
        let i: number = 0;
        let timeNumber: number;
        date = new Date(date)

        if( !( date instanceof Date ) ){
            return '';
        }

        for (let day of days) {
            timeNumber = i++ * oneDay;
            if( NiceDatePipe.isNextDay( date, timeNumber) ){
                return `${day}`;
            }
        }

        const dateTime = date.getTime();

        for (let day of closeDays) {

            const endTimeNumber = oneDay * day.num;
            const start = new Date().getTime() + timeNumber;
            const end = new Date().getTime() + endTimeNumber;
            timeNumber = endTimeNumber;

            if( dateTime > start && dateTime < end ){
                return formatDate(date, day.format, this.locale);
            }
        }

        return formatDate(date, defaultFormat, this.locale);
    }

    static isNextDay(date: Date, is: number): boolean{
        const mockup = new Date(new Date().getTime() + is );

        return date.getDate() == mockup.getDate() &&
            date.getMonth() == mockup.getMonth() &&
            date.getFullYear() == mockup.getFullYear();
    }

}