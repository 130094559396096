import {
  Component,
  Inject,
  OnInit,
  PLATFORM_ID,
  ViewEncapsulation,
} from "@angular/core";
import { Router, NavigationEnd } from "@angular/router";
import { isPlatformServer } from "@angular/common";
import { DataProviderService } from "./services/data-provider.service";
import { SharedService } from "./shared/services/shared.service";

@Component({
  selector: "app-root",
  templateUrl: "./app.component.html",
  styleUrls: ["./styles/app.scss"],
  encapsulation: ViewEncapsulation.None,
})
export class AppComponent implements OnInit {
  constructor(
    private router: Router,
    @Inject(PLATFORM_ID) private platformId: any,
    private _dataProviderService: DataProviderService,
    private sharedService: SharedService
  ) {}

  ngOnInit() {
    this.sharedService.fetchSettings();
    this.sharedService.fetchCategories();
    this.router.events.subscribe((evt) => {
      if (
        isPlatformServer(this.platformId) ||
        !(evt instanceof NavigationEnd)
      ) {
        return;
      }
      if (0 !== evt.url.indexOf("/user")) {
        window.scrollTo(0, 0);
      }
    });

    let isLoggedIn = this._dataProviderService.getByKeyName("SESSION_TOKEN")
      ? true
      : false;
    this._dataProviderService.updateLoginSub(isLoggedIn);
  }
}
