import { Component, Input, OnInit } from "@angular/core";

@Component({
  selector: "app-no-data-found",
  templateUrl: "./no-data-found.component.html",
  styleUrls: ["./no-data-found.component.scss"],
})
export class NoDataFoundComponent implements OnInit {
  @Input() heading: string = "No Data Found";
  @Input() description: string =
    "We can’t find the item you are searching for, maybe a little spelling mistake?";

  constructor() {}

  ngOnInit(): void {}
}
