<div class="modal modal--center">
    <header class="alignCenter justifyCenter dflex">
        <h2>{{data?.title}}</h2>
    </header>
    
    <p>{{data?.description}}</p>
    <div class="dflex alignCenter modal__buttonCol justifySpaceBetween">
        <app-button mode="dark" [label]="'No'" mat-dialog-close></app-button>
        <app-button [label]="'Yes'" [mat-dialog-close]="true"></app-button>
    </div>
</div>