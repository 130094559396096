<!-- <div class="addnewAddress">
    <span class="close" mat-dialog-close> <img src="assets/images/cross-popup.svg" alt="cross" title="cross"></span>
    <h2>Add new address</h2>
    <div class="addressInput">
        <app-address-bar [location]="data?.addressToBeEdited?.address || ''" (locationRemoved)="locationRemoved($event)"
            (selectedAddress)="selectedAddress($event)"></app-address-bar>
    </div>
    <div class="addnewAddress__map">
        <app-map></app-map>
    </div>
    <app-button [showLoader]="clicked" [isDisabled]="clicked || disabled || (!disabled && !isAddressSupported)"
        [label]="'Save'" (action)="saveAddress()">
    </app-button>
</div> -->

<div class="modal">
    <header class="dflex alignCenter justifySpaceBetween">
        <h2>Add new address</h2>
        <span class="modal__close" mat-dialog-close> <img src="assets/images/cross-popup.svg" alt="cross" title="cross"></span>
        </header>
   
   
    <div class="addressInput">
        <app-address-bar [location]="data?.addressToBeEdited?.address || ''" (locationRemoved)="locationRemoved($event)"
            (selectedAddress)="selectedAddress($event)"></app-address-bar>
    </div>
    <div class="modal__map">
        <app-map></app-map>
    </div>
    <div class="modal__button">
    <app-button [showLoader]="clicked" [isDisabled]="clicked || disabled || (!disabled && !isAddressSupported)"
        [label]="'Save'" (action)="data?.edit ? editAddress() : addAddress()">
    </app-button>
</div>