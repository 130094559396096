import { Injectable } from "@angular/core";
import { BehaviorSubject, Observable } from "rxjs";
import { APIS } from "../../constants/api.contants";
import { HttpService } from "../../services/http.service";

@Injectable({
  providedIn: "root",
})
export class LocationService {
  locationSubject: BehaviorSubject<any> = new BehaviorSubject({});
  markerPositionSubject: BehaviorSubject<any> = new BehaviorSubject(null);
  mapCenterSubject: BehaviorSubject<any> = new BehaviorSubject(null);
  addressSubject: BehaviorSubject<any> = new BehaviorSubject(null);

  constructor(private httpService: HttpService) {}

  updateLocation(location) {
    this.locationSubject.next(location);
  }

  updateLatLng(latLng) {
    this.updateMarkerPosition(latLng);
    this.updateMapCenter(latLng);
    this.updateAddress(latLng);
  }

  updateMarkerPosition(data) {
    let markerPosition = this.markerPositionSubject.value;
    this.markerPositionSubject.next({
      ...markerPosition,
      ...data,
    });
  }

  updateMapCenter(data) {
    let mapCenter = this.mapCenterSubject.value;
    this.mapCenterSubject.next({
      ...mapCenter,
      ...data,
    });
  }

  updateAddress(data) {
    let address = this.addressSubject.value;
    this.addressSubject.next({
      ...address,
      ...data,
    });
  }

  autocomplete(
    address: string,
    txn?: string,
    limit: number = 10
  ): Observable<any> {
    return this.httpService.callCustomRequest("autocomplete", {
      address: address,
      limit: limit,
      txn: txn,
    });
  }

  findAddress(payload: {
    address: string;
    addressId: number;
    selection;
  }): Observable<any> {
    const param = { address: payload?.address, addressElements: true };
    if (null !== payload?.addressId) {
      param["addressId"] = payload?.addressId;
    }
    if (null !== payload?.selection) {
      param["selection"] = payload?.selection;
    }
    return this.httpService.callCustomRequest("FindAddress", param);
  }

  getEcadData(ecadid: string): Observable<any> {
    return this.httpService.callCustomRequest("GetEcadData", {
      ecadid: ecadid,
    });
  }

  reverseGeocode(
    latitude: number,
    longitude: number,
    maxDistance: number = 50
  ): Observable<any> {
    return this.httpService.callCustomRequest("ReverseGeocode", {
      longitude: longitude,
      latitude: latitude,
      maxDistance: maxDistance,
    });
  }

  checkAvailableAddress(payload) {
    return this.httpService.callPostApi(APIS.availability.check, payload);
  }

  addAddress(payload) {
    return this.httpService.callPostApi(APIS.address.add, payload);
  }

  editAddress(payload) {
    return this.httpService.callPutApi(APIS.address.edit + payload.id, payload);
  }
}
