import { Injectable } from "@angular/core";
import {
  HttpRequest,
  HttpHandler,
  HttpEvent,
  HttpInterceptor,
} from "@angular/common/http";
import { Observable } from "rxjs";
import { APIS } from "../constants/api.contants";
import { environment } from "../../environments/environment";
import { DataProviderService } from "../services/data-provider.service";

@Injectable()
export class InterceptInterceptor implements HttpInterceptor {
  authApis = [APIS.user.addresses, APIS.user.me, APIS.coupon.validate];

  constructor(private _dataProviderService: DataProviderService) {}

  intercept(
    request: HttpRequest<unknown>,
    next: HttpHandler
  ): Observable<HttpEvent<unknown>> {
    let url = request?.url.split(environment.API_URL)[1];
    let findUrl = this.authApis.findIndex((path: string) =>
      url?.includes(path)
    );
    if (findUrl != -1) {
      request = this.addToken(request);
    }
    return next.handle(request);
  }

  addToken(request: HttpRequest<any>) {
    let token: string | null =
      this._dataProviderService.getByKeyName("SESSION_TOKEN");
    return request.clone({
      setHeaders: {
        Authorization: `Bearer ${token}`,
      },
    });
  }
}
