<header class="header flex alignCenter" [ngClass]="{'header__active':expandMenu, 'isHomePage':isHomePage}">
    <div class="wrapper flex alignCenter justifySpaceBetween">
        <app-logo></app-logo>
        <div class="header__toggle">
            <a class="flex" (click)="toggleMenu()">
                <img src="/assets/images/menu-bar.svg" alt="Menu">
            </a>
        </div>
        <nav class="flex alignCenter justifyEnd">
            <span class="cross-icon">
                <img (click)="toggleMenu()" src="/assets/images/close-menu.svg" alt="Menu">
            </span>
            <ul class="flex alignCenter">
                <li>
                    <a [routerLink]="['/']" [routerLinkActive]="'active'" [routerLinkActiveOptions]="{ exact: true }">
                        Home
                    </a>
                </li>
                <li>
                    <a [routerLink]="['/about']" [routerLinkActive]="'active'">
                        About us
                    </a>
                </li>
                <li>
                    <a [routerLink]="['/blogs']" [routerLinkActive]="'active'">
                        Blogs
                    </a>
                </li>
                <li>
                    <a [routerLink]="['/pricing']" [routerLinkActive]="'active'">
                        Pricing & Services
                    </a>
                </li>
                <li>
                    <a [routerLink]="['/faq']" [routerLinkActive]="'active'">
                        FAQ
                    </a>
                </li>
                <li>
                    <ng-container *ngIf="!isLoggedIn;else loggedIn">
                        <a [routerLink]="['/auth/login']">Login</a>
                    </ng-container>
                </li>
                <li>
                    <app-button [routerLink]="['/app']" [label]="'Order now'"></app-button>
                </li>
            </ul>
        </nav>
    </div>
</header>

<div class="header__empty" [ngClass]="{'isHomePage':isHomePage}"></div>


<ng-template #loggedIn>
    <div class="user" appClickOutside (clickOutside)="toggleNavdown(false);$event.stopPropagation();">
        <ng-container *ngIf="userName?.length">
            <span class="flex alignCenter justifyCenter"
                (click)="toggleNavdown(!expandDropdown); $event.preventDefault(); $event.stopPropagation();"
                *ngIf="userName?.length">
                {{userName}}
            </span>
            <ul [ngClass]="{'active':expandDropdown}">
                <li>
                    <a [routerLink]="['/profile/profile-details']">Account</a>
                </li>
                <li>
                    <a (click)="logOut()">Logout</a>
                </li>
            </ul>
        </ng-container>
    </div>
</ng-template>