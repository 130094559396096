import { Component, Input, OnInit } from "@angular/core";

@Component({
  selector: "app-skeletons",
  templateUrl: "./skeletons.component.html",
  styleUrls: ["./skeletons.component.scss"],
})
export class SkeltonsComponent implements OnInit {
  @Input() type: "circle" | "bar" | any = "bar";
  @Input() height!: string;
  @Input() width: string = "100%";
  @Input() radius: string = "6px";
  @Input() margin: string = "0";
  @Input() count: string = "1";

  constructor() {}

  ngOnInit(): void {}
}
