import { Component, Renderer2, ViewEncapsulation } from "@angular/core";
import { Router } from "@angular/router";
import { DeviceDetectorService } from "ngx-device-detector";

@Component({
  selector: "app-mobile-banner",
  styleUrls: ["./mobile-banner.component.scss"],
  templateUrl: "./mobile-banner.component.html",
  encapsulation: ViewEncapsulation.None,
})
export class MobileBannerComponent {
  public visible: boolean = false;

  public data: any = {};

  constructor(
    public router: Router,
    private deviceService: DeviceDetectorService,
    private renderer: Renderer2
  ) {
    this.visible = this.deviceService.isMobile();
    const device = this.deviceService.getDeviceInfo().os.toLowerCase();

    const mobile = this.deviceService.isMobile();
    const browser = this.deviceService.getDeviceInfo().browser.toLowerCase();
    const version = parseInt(
      this.deviceService.getDeviceInfo().browser_version
    );
    const exactVersion = parseFloat(
      this.deviceService.getDeviceInfo().browser_version
    );
    const os = this.deviceService.getDeviceInfo().os.toLowerCase();
    let imageFormat = "jpg_png";

    let _this = this;

    function setHTMLClass(height, className) {
      if (height == 2) {
        _this.renderer.addClass(document.body, className);
      } else {
        document.documentElement.className += " not" + className;

        _this.renderer.addClass(document.body, "not" + className);
      }
    }

    var WebP = new Image();
    WebP.onload = WebP.onerror = function () {
      setHTMLClass(WebP.height, "webp");
    };
    WebP.src =
      "data:image/webp;base64,UklGRjoAAABXRUJQVlA4IC4AAACyAgCdASoCAAIALmk0mk0iIiIiIgBoSygABc6WWgAA/veff/0PP8bA//LwYAAA";

    var JPX = new Image();
    JPX.onload = JPX.onerror = function () {
      setHTMLClass(JPX.height, "jpx");
    };
    JPX.src =
      "data:image/vnd.ms-photo;base64,SUm8AQgAAAAFAAG8AQAQAAAASgAAAIC8BAABAAAAAQAAAIG8BAABAAAAAgAAAMC8BAABAAAAWgAAAMG8BAABAAAARgAAAAAAAAAkw91vA07+S7GFPXd2jckQV01QSE9UTwAZAMFxAAAAATAAoAAKAACgAAAQgCAIAAAEb/8AAQAAAQDCPwCAAAAAAAAAAAAAAAAAjkI/AIAAAAAAAAABIAA=";

    var JP2 = new Image();
    JP2.onload = JP2.onerror = function () {
      setHTMLClass(JP2.height, "jp2");
    };
    JP2.src =
      "data:image/jp2;base64,/0//UQAyAAAAAAABAAAAAgAAAAAAAAAAAAAABAAAAAQAAAAAAAAAAAAEBwEBBwEBBwEBBwEB/1IADAAAAAEAAAQEAAH/XAAEQED/ZAAlAAFDcmVhdGVkIGJ5IE9wZW5KUEVHIHZlcnNpb24gMi4wLjD/kAAKAAAAAABYAAH/UwAJAQAABAQAAf9dAAUBQED/UwAJAgAABAQAAf9dAAUCQED/UwAJAwAABAQAAf9dAAUDQED/k8+kEAGvz6QQAa/PpBABr994EAk//9k=";

    //https://caniuse.com/#feat=webp
    //https://caniuse.com/#feat=jpegxr
    //https://caniuse.com/#feat=jpeg2000

    switch (true) {
      case !mobile && browser === "chrome" && version >= 23:
        imageFormat = "webp";
        break;
      case !mobile && browser === "firefox" && version >= 65:
        imageFormat = "webp";
        break;
      case !mobile && browser === "ms-edge" && version >= 18:
        imageFormat = "webp";
        break;
      case !mobile && browser === "opera" && exactVersion >= 12.1:
        imageFormat = "webp";
        break;
      case mobile &&
        browser === "opera" &&
        this.deviceService.getDeviceInfo().userAgent.indexOf("Opera Mini") >= 0:
        imageFormat = "webp";
        break;
      case mobile &&
        browser === "opera" &&
        this.deviceService.getDeviceInfo().userAgent.indexOf("Opera Mobi") >=
          0 &&
        version >= 12:
        imageFormat = "webp";
        break;
      case mobile && browser === "safari" && device === "android": // firefox && android browser
        imageFormat = "webp";
        break;
      case mobile && browser === "chrome" && version >= 23:
        imageFormat = "webp";
        break;
      case mobile &&
        browser === "uc-browser" &&
        device === "android" &&
        exactVersion >= 12.12:
        imageFormat = "webp";
        break;
      case mobile &&
        browser === "samsung" &&
        device === "android" &&
        version >= 4:
        imageFormat = "webp";
        break;
      case mobile && device === "android":
        imageFormat = "webp"; //////
        break;

      case !mobile && browser === "ie" && version >= 9:
        imageFormat = "jxr";
        break;
      case !mobile && browser === "ms-edge" && version >= 12:
        imageFormat = "jxr";
        break;

      case !mobile && browser === "safari" && version >= 5:
        imageFormat = "jp2";
        break;
      case mobile && browser === "safari" && version >= 5:
        imageFormat = "jp2";
        break;

      default:
        imageFormat = "jpg_png";
    }

    this.renderer.addClass(document.body, "rendered");
    this.renderer.addClass(document.body, mobile ? "mobile" : "desktop");
    this.renderer.addClass(document.body, "browser_" + browser);
    this.renderer.addClass(document.body, "browser_version_" + version);
    this.renderer.addClass(
      document.body,
      "browser_exact_version_" + exactVersion
    );
    this.renderer.addClass(document.body, "os_" + os);
    this.renderer.addClass(document.body, "os_" + os);
    this.renderer.addClass(document.body, imageFormat);

    //browser Safari browser_version "12.0" , os Mac  iOS

    if (device.toLowerCase() == "android") {
      this.data = {
        os: device,
        number: 40,
        url: "https://play.google.com/store/apps/details?id=ie.laundr.laundrie&hl=en",
      };
    } else {
      this.data = {
        os: device,
        number: 60,
        url: "https://apps.apple.com/ie/app/laundrie/id998909515",
      };
    }
  }

  close() {
    this.visible = false;
  }
}
