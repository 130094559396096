import { AbstractControl, FormGroup } from "@angular/forms";

export class RegularExpressionConstant {
  static EMAIL: RegExp = /^[A-Za-z0-9._+-]+@[A-Za-z0-9.-]+\.[A-Za-z]{2,4}$/;
  // /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
  static PASSWORD: string =
    "(?=.*[a-z])(?=.*[A-Z])(?=.*[0-9])(?=.*[$@$!%*?&])[A-Za-zd$@$!%*?&].{7,}";
  static PHONE: string = "^(\\+?d{1,4}[s-])?(?!0+s+,?$)\\d{9}s*,?$";
}

export function noWhitespaceValidator(control: AbstractControl) {
  let val = control?.value?.trim();
  let isValid = true;
  if (val?.length < 1) {
    isValid = false;
  }
  return isValid ? null : { minlength: true };
}

export function passwordMatchValidator(
  controlName: string,
  matchingControlName: string
) {
  return (formGroup: FormGroup) => {
    const control = formGroup.controls[controlName];
    const matchingControl = formGroup.controls[matchingControlName];
    if (matchingControl.errors && !matchingControl.errors.confirmedValidator) {
      return;
    }
    if (control.value !== matchingControl.value) {
      matchingControl.setErrors({ confirmedValidator: true });
    } else {
      matchingControl.setErrors(null);
    }
  };
}
