import { Component, Input, OnDestroy, OnInit } from "@angular/core";
import { FormBuilder, FormGroup, Validators } from "@angular/forms";
import { Router } from "@angular/router";
import { Subject, takeUntil } from "rxjs";
// import { AuthService } from "../../../../pages/auth/services/auth.service";
import { SnackbarService } from "../../../atoms/components/snackbar/snackbar.service";
import { DataProviderService } from "../../../services/data-provider.service";
import {
  noWhitespaceValidator,
  RegularExpressionConstant,
} from "../../../validators/validator";
import { ILoggedInUser } from "../../models/auth.models";
import { AuthService } from "../../services/auth.service";

@Component({
  selector: "app-login",
  templateUrl: "./login.component.html",
  styleUrls: ["./login.component.scss"],
})
export class LoginComponent implements OnInit, OnDestroy {
  @Input() hasLoginPage: boolean = true;
  loginForm!: FormGroup;
  clicked: boolean = false;
  displayPassword: boolean = false;
  private _unsubscribeAll: Subject<any> = new Subject<any>();

  constructor(
    private _fb: FormBuilder,
    private snackbarService: SnackbarService,
    private authService: AuthService,
    private router: Router,
    private _dataProviderService: DataProviderService
  ) {}

  ngOnInit(): void {
    this.initLoginForm();
  }

  initLoginForm() {
    this.loginForm = this._fb.group({
      email: [
        "",
        [
          Validators.required,
          Validators.pattern(RegularExpressionConstant?.EMAIL),
        ],
      ],
      password: [
        "",
        [
          Validators.required,
          Validators.minLength(6),
          noWhitespaceValidator,
          // Validators.maxLength(26),
          // Validators.pattern(RegularExpressionConstant.PASSWORD),
        ],
      ],
      rememberMe: [false, [Validators.required]],
    });
  }

  showPassword() {
    this.displayPassword = !this.displayPassword;
  }

  submitLoginForm() {
    this.clicked = true;
    // const { email, password, rememberMe } = this.loginForm.value;
    this.authService
      .login(this.loginForm.value)
      .pipe(takeUntil(this._unsubscribeAll))
      .subscribe({
        next: (res: ILoggedInUser) => {
          this.loginForm.reset();
          this._dataProviderService.setLoginData(res);
          this.snackbarService.openSnackBar("Logged In successfully.");
          this.clicked = false;
          this.router.navigate(["/app"]);
        },
        error: (err) => {
          console.error(err);
          this.snackbarService.openSnackBar(err?.error.message, "error", 5000);
          this.clicked = false;
        },
      });
    // this.authService
    //   .signin(email, password, rememberMe)
    //   .pipe(takeUntil(this._unsubscribeAll))
    //   .subscribe({
    //     next: (res) => {
    //       this.loginForm.reset();
    //       this.snackbarService.openSnackBar("Logged In successfully.");
    //       this.clicked = false;
    //       this.router.navigate(['/']);
    //     },
    //     error: (err) => {
    //       console.error(err);
    //       this.snackbarService.openSnackBar(err?.error.message,'error',5000);
    //       this.clicked = false;
    //     },
    //   });
  }

  /**
   * On destroy
   */
  ngOnDestroy(): void {
    // Unsubscribe from all subscriptions
    this._unsubscribeAll.next(true);
    this._unsubscribeAll.complete();
  }

  get emailErrorMessage() {
    let message = "";
    if (this.loginForm.controls?.email?.errors) {
      if (this.loginForm.controls?.email?.errors?.required) {
        message = "This field is required";
      } else if (this.loginForm.controls?.email?.errors?.pattern) {
        message = "Please enter a valid email";
      } else if (this.loginForm.controls?.email?.errors?.email) {
        message = "Please enter a valid email";
      }
    }
    return message;
  }

  get passwordErrorMessage() {
    let msg = "";
    if (
      this.loginForm?.controls?.password?.touched &&
      this.loginForm?.controls?.password?.errors
    ) {
      if (this.loginForm?.controls?.password?.errors?.required) {
        msg = "This field is required";
      } else if (this.loginForm.controls["password"].errors?.minlength) {
        msg = "Must be minimum 6 characters";
      } else if (this.loginForm.controls["password"].errors?.maxlength) {
        msg = "Must be maximum 26 characters";
      }
      if (this.loginForm?.controls?.password?.errors?.pattern) {
        msg =
          "Must be at least 8 characters and must contain 1 uppercase letter, 1 lowercase letter, 1 number and 1 special character.";
      }
    }
    return msg;
  }
}
