import {
  Component,
  EventEmitter,
  Input,
  OnDestroy,
  OnInit,
  Output,
  SimpleChanges,
} from "@angular/core";
import { Subject, takeUntil } from "rxjs";
import { LocationService } from "../../services/location.service";
import { capitalizeFirstLetter } from "../../../utils/utils";

@Component({
  selector: "app-address-bar",
  templateUrl: "./address-bar.component.html",
  styleUrls: ["./address-bar.component.scss"],
})
export class AddressBarComponent implements OnInit, OnDestroy {
  @Output() selectedAddress: EventEmitter<any> = new EventEmitter();
  @Output() locationRemoved: EventEmitter<any> = new EventEmitter();
  @Input() location!: string;
  @Input() placeholder: string = "e.g. 12 Grafton Street, Dublin 2";

  _unsubscribeAll: Subject<void> = new Subject<void>();
  address: any = {};
  suggestions!: string[];
  searchTimeoutRef;
  showInvalidAddressError: boolean = false;
  addressError: string;
  txnId!: string;

  constructor(private locationService: LocationService) {}
  ngOnInit(): void {}

  searchLocation() {
    this.locationRemoved.emit(true);
    if (!this.location?.trim()?.length) {
      this.setSugestions([]);
      return;
    }
    this.showInvalidAddressError = false;
    if (this.searchTimeoutRef) {
      clearTimeout(this.searchTimeoutRef);
      this.searchTimeoutRef = null;
    }
    this.searchTimeoutRef = setTimeout(() => {
      this.locationService.autocomplete(this.location, this.txnId).subscribe({
        next: (res) => {
          this.txnId = res?.input?.txn;
          this.setSugestions(res.options);
        },
        error: (err) => {
          console.error(err);
        },
      });
    }, 500);
  }

  findAddress(address) {
    let payload = {
      address: address?.displayName,
      addressId: address?.addressId,
      selection: -1,
    };
    this.locationService
      .findAddress(payload)
      .pipe(takeUntil(this._unsubscribeAll))
      .subscribe({
        next: (res) => {
          this.address.address = address?.displayName;
          this.getEcadData(address);
        },
        error: (err) => {
          console.error(err);
        },
      });
  }

  getEcadData(address) {
    this.locationService
      .getEcadData(address.addressId)
      .pipe(takeUntil(this._unsubscribeAll))
      .subscribe({
        next: (res) => {
          const latLng = {
            latitude: res?.spatialInfo?.etrs89?.location.latitude,
            longitude: res?.spatialInfo?.etrs89?.location.longitude,
          };
          if (res.eircodeInfo != null && res.eircodeInfo.eircode != null) {
            this.address["eircode"] = res.eircodeInfo.eircode;
          } else {
            this.address["eircode"] = this.findEircode(
              res?.postalAddress?.english
            );
          }
          this.address.latitude = res?.spatialInfo?.etrs89?.location.latitude;
          this.address.longitude = res?.spatialInfo?.etrs89?.location.longitude;
          this.locationService.updateLatLng(latLng);
          this.checkLocation();
        },
        error: (err) => {
          console.error(err);
        },
      });
  }

  checkLocation() {
    this.locationService
      .checkAvailableAddress(this.address)
      .pipe(takeUntil(this._unsubscribeAll))
      .subscribe({
        next: (res: { support: boolean; message: string }) => {
          this.showInvalidAddressError = !res.support;
          this.locationRemoved.emit(this.showInvalidAddressError);
          this.addressError = res?.message ? capitalizeFirstLetter(res?.message) : '';
          if (!this.showInvalidAddressError) {
            this.selectedAddress.emit({ ...this.address });
            this.txnId = undefined;
          } else {
            this.locationRemoved.emit(this.showInvalidAddressError);
          }
        },
        error: (err) => {
          console.error(err);
        },
      });
  }

  setSugestions(list: string[]) {
    this.suggestions = list || [];
  }

  selectLocation(loc: any) {
    this.location = loc?.displayName;
    this.findAddress(loc);
    this.setSugestions([]);
  }

  private findEircode(elements: Array<any>): string {
    for (let element of elements) {
      if (element.hasOwnProperty("type") && element.hasOwnProperty("value")) {
        //# DublinPostalArea text
        if (element.type !== 12) {
          continue;
        }

        element = element.value;
      }
      const m = element.match(/^Dublin ([0-9w]{1,2})$/i);
      if (m != null && m[1] != null) {
        let d = "D";
        if (m[1].length === 1) {
          d += "0";
        }
        d += m[1];
        return d;
      }
    }

    return "";
  }
  /**
   * On destroy
   */
  ngOnDestroy(): void {
    // Unsubscribe from all subscriptions
    this._unsubscribeAll.next();
    this._unsubscribeAll.complete();
  }
}
