import { Component, OnDestroy, OnInit } from "@angular/core";
import { NavigationEnd, Router } from "@angular/router";
import { Subject, takeUntil } from "rxjs";
import { CheckoutService } from "../../../order-now/services/checkout.service";
import { StepperService } from "../../../order-now/services/stepper.service";
import { DataProviderService } from "../../../services/data-provider.service";

@Component({
  selector: "app-header",
  templateUrl: "./header.component.html",
  styleUrls: ["./header.component.scss"],
})
export class HeaderComponent implements OnInit, OnDestroy {
  isHomePage: boolean = false;
  isLoggedIn: boolean = false;
  userName: string;
  displayHiddenButton: boolean = false;
  private _unsubscribeAll: Subject<void> = new Subject<void>();

  constructor(
    private _dataProviderService: DataProviderService,
    private _router: Router,
    private stepperService: StepperService,
    private checkoutService: CheckoutService
  ) {}

  expandMenu: boolean = false;
  expandDropdown: boolean = false;
  ngOnInit(): void {
    this.subscribeRoutes();
    this.subsLoggedInSub();
    this.subsUserDetails();
  }

  subscribeRoutes() {
    this.isHomePage = window.location.pathname === "/";
    if (this._router.url.includes("/app")) {
      this.displayHiddenButton = true;
    }
    this._router.events.pipe(takeUntil(this._unsubscribeAll)).subscribe((s) => {
      if (s instanceof NavigationEnd) {
        this.isHomePage = s.url === "/";
        this.displayHiddenButton = s?.url.includes("/app");
        this.expandMenu = false;
        this.expandDropdown = false;
      }
    });
  }

  subsLoggedInSub() {
    this._dataProviderService.loggedInSub
      .pipe(takeUntil(this._unsubscribeAll))
      .subscribe({
        next: (value: boolean) => {
          this.isLoggedIn = value || false;
        },
      });
  }

  subsUserDetails() {
    this._dataProviderService.userDetails
      .pipe(takeUntil(this._unsubscribeAll))
      .subscribe({
        next: (value: any) => {
          if (value) {
            this.userName = `${value?.first_name?.[0]?.toUpperCase() || ""}${
              value?.last_name?.[0]?.toUpperCase() || ""
            }`;
          }
        },
      });
  }

  toggleMenu() {
    this.expandMenu = !this.expandMenu;
  }
  toggleNavdown(value?: boolean) {
    this.expandDropdown = value;
  }

  logOut() {
    this.stepperService.resetSteps();
    this.checkoutService.resetOrderSummary();
    this._dataProviderService.logout();
    if (
      window.location.pathname.includes("/profile") ||
      window.location.pathname.includes("/complete")
    ) {
      this._router.navigate(["/"]);
    }
  }

  /**
   * On destroy
   */
  ngOnDestroy(): void {
    // Unsubscribe from all subscriptions
    this._unsubscribeAll.next();
    this._unsubscribeAll.complete();
  }
}
