export interface IloginForm {
  email: string;
  password: string;
  rememberMe?: boolean;
}

export interface ISignupPayload {
  first_name: string;
  last_name: string;
  phone: string;
  email: string;
  password: string;
}

export interface IChangePasswordPayload {
  password: string;
  token: string;
}


export interface ILoggedInUser{
  token: string;
  expires_at: string
}