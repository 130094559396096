import { NgModule } from "@angular/core";
import { CommonModule } from "@angular/common";
import { AtomsModule } from "../atoms/atoms.module";
import { NoDataFoundComponent } from "./components/no-data-found/no-data-found.component";
// import { FieldAlertComponent } from "./components/field-alert/field-alert.component";

const MODULES = [AtomsModule];

const COMPONENTS = [NoDataFoundComponent];

@NgModule({
  declarations: [...COMPONENTS],
  imports: [CommonModule, ...MODULES],
  exports: [...MODULES, ...COMPONENTS],
})
export class MoleculesModule {}
