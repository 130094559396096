import { Injectable } from "@angular/core";
import { MatSnackBar } from "@angular/material/snack-bar";
import { SnackbarComponent } from "./snackbar.component";

@Injectable({
  providedIn: "root",
})
export class SnackbarService {
  constructor(private snackBar: MatSnackBar) {}

  public openSnackBar(
    message?: string,
    type: "success" | "error" | "warning" | "info" = "success",
    duration: number = 5000,
    outline?: string
  ) {
    let msg = type === 'error' ? "Something went wrong, please try again later." : "";
    this.snackBar.openFromComponent(SnackbarComponent, {
      duration: duration,
      horizontalPosition: "right",
      // panelClass: panelClass,
      verticalPosition: "top",
      data: {
        message: message || msg,
        snackType: type,
        snackBar: this.snackBar,
        outline: outline,
      },
    });
  }
}
