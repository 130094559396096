import { Injectable } from "@angular/core";
@Injectable({
  providedIn: "root",
})
export class ProductAdaptor {
  adapt(res: any) {
    return this.updateData(res);
  }

  updateData(res) {
    return res?.map((prod: any) => {
      prod["items"] = prod?.items?.map((item: any) => {
        return { ...item, count: 0 };
      });
      return prod;
    });
  }
}
