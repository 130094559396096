import { Component, EventEmitter, Input, OnInit, Output } from "@angular/core";

export interface IActionEvent {
  type: string;
  address: any;
}
@Component({
  selector: "app-address-card",
  templateUrl: "./address-card.component.html",
  styleUrls: ["./address-card.component.scss"],
})
export class AddressCardComponent implements OnInit {
  @Input() addressDetails: any;

  @Output() actionEvent: EventEmitter<IActionEvent> = new EventEmitter();

  constructor() {}

  ngOnInit(): void {}

  action(type: string) {
    this.actionEvent.emit({ type, address: this.addressDetails });
  }
}
