import { NgModule } from "@angular/core";
import { CommonModule } from "@angular/common";
import { MoleculesModule } from "../molecules/molecules.module";
// import { ServiceElementComponent } from "./components/carousel/service-element/service-element.component";

const MODULES = [MoleculesModule];

const COMPONENTS = [];
@NgModule({
  declarations: [...COMPONENTS],
  imports: [CommonModule, ...MODULES],
  exports: [...MODULES, ...COMPONENTS],
})
export class OrganismsModule {}
