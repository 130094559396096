import { StepMapping } from "../enums/steps.enum";
import { IStep } from "../models/steps.models";

export const STEPPERS_LIST: IStep[] = [
  {
    STEP_NUMBER: StepMapping.DEFAULT,
    LABEL: "Address",
    IMG_URL: "/assets/images/address.svg",
    ROUTE: "/app",
    IS_ACTIVE: true,
  },
  {
    STEP_NUMBER: StepMapping.SERVICES,
    LABEL: "Services",
    IMG_URL: "/assets/images/services.svg",
    ROUTE: "/app/services",
    IS_ACTIVE: false,
  },
  {
    STEP_NUMBER: StepMapping.CONTACT_DETAILS,
    LABEL: "Contact details",
    IMG_URL: "/assets/images/contact.svg",
    ROUTE: "/app/contact-details",
    IS_ACTIVE: false,
  },
  {
    STEP_NUMBER: StepMapping.PAYMENT,
    LABEL: "Payment",
    IMG_URL: "/assets/images/payment.svg",
    ROUTE: "/app/payment",
    IS_ACTIVE: false,
  },
];
