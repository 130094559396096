import { Injectable } from "@angular/core";
import { BehaviorSubject } from "rxjs";
import { ILoggedInUser } from "../auth/models/auth.models";
import { APIS } from "../constants/api.contants";
import { HttpService } from "./http.service";

@Injectable({
  providedIn: "root",
})
export class DataProviderService {
  loggedInSub: BehaviorSubject<boolean> = new BehaviorSubject(false);
  userDetails: BehaviorSubject<any> = new BehaviorSubject<any>(undefined);

  constructor(private _httpService: HttpService) {}

  getSessionKey() {
    return "";
  }

  setSessionKey(sessionKey) {}

  setLoginData(res: ILoggedInUser) {
    localStorage.setItem("SESSION_TOKEN_EXPIRES", res?.expires_at);
    localStorage.setItem("SESSION_TOKEN", res?.token);
    this.updateLoginSub(true);
  }

  updateLoginSub(value: boolean) {
    this.loggedInSub.next(value);
    if (value || this.loggedInSub.value) {
      this.fetchUserDetails();
    }
  }

  fetchUserDetails() {
    this._httpService.callGetApi(APIS.user.me).subscribe((res) => {
      this.userDetails.next(res);
    });
  }

  setByKeyName(key, value) {
    localStorage.setItem(key, JSON.stringify(value));
  }

  removeKeyByName(key) {
    localStorage.removeItem(key);
  }

  getByKeyName(key) {
    return localStorage.getItem(key);
  }

  getStorageDataByKeyName(key) {
    return JSON.parse(JSON.stringify(localStorage.getItem(key) || {}));
  }

  logout() {
    this.updateLoginSub(false);
    ["SESSION_TOKEN_EXPIRES", "SESSION_TOKEN", "user", "cart"].forEach(
      (key: string) => {
        this.removeKeyByName(key);
      }
    );
  }
}
