import {Inject, LOCALE_ID, Pipe, PipeTransform} from '@angular/core';
import {DatePipe, formatDate} from "@angular/common";

@Pipe({ name: 'pastDate' })
export class PastDatePipe implements PipeTransform{

    private tab = { 'z': -1000, 's': 1000, 'm': 60000, 'h': 3600000, 'D': 86400000, 'W': 604800000, 'M': 2592000000, 'Y': 31536000000 };
    private regex = /^\d+([a-z]){1}$/i;

    constructor(@Inject(LOCALE_ID) private locale: string) {}

    transform(
        date: Date,
        format: string = 'placed [num] [unit] ago',
        defaultFormat: string = "'place' on MMMM d, y",
        units = { 'just now': '10z', 'seconds': '60s', 'minutes': '60m', 'hours': '24h', 'days': '14D', 'weeks': '8W', 'months': '12M'}
    ) : string{

        const now = new Date().getTime();
        const delta = now - date.getTime();
        const unitsRight = this.convertUnitsToNumber(units);
        let m = 0;

        for( let key in unitsRight ){
            const unit = unitsRight[key];
            if( unit.interval > delta ){
                return format.replace('[num]',  ( m = Math.floor(delta / unit.unit ) ) >= 0 ? `${m}` : '' ).replace('[unit]', key);
            }
        }

        return formatDate(date, defaultFormat, this.locale);
    }


    convertUnitsToNumber(units: Object): Object{

        const obj = {};

        for( let key in units ){
            const unit = units[key];
            let m = null;
            obj[key] = ((m = this.regex.exec(unit)) !== null)
                ? this.convert( unit, m[1] )
                : parseInt(unit)
            ;
        }

        return obj;
    }

    convert(value: string, unit: string): { interval: number, unit: number }{
        if( !this.tab.hasOwnProperty(unit) ){
            console.error("bad unit");
            return {interval: 0, unit: 0};
        }

        return {
            interval: Math.abs(parseInt(value) * this.tab[unit]),
            unit: this.tab[unit]
        };
    }

}