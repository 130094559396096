<div *ngIf="field.invalid && (field.dirty || field.touched)" class="field-invalid-message">
    <ng-template ngFor let-error [ngForOf]="field.errors | keyvalue" let-i="index">
        <div [ngSwitch]="error.key">
            <ng-container *ngSwitchCase="'required'">
                This field is required
            </ng-container>
            <ng-container *ngSwitchCase="'pattern'">
                <div #ref><ng-content select=".pattern-invalid-message"></ng-content></div>
                <span *ngIf="ref.children.length == 0">
                    This format is invalid. Try use this one {{error.value.requiredPattern}}
                </span>
            </ng-container>
            <ng-container *ngSwitchCase="'email'">
                Provided email is not valid.
            </ng-container>
            <ng-container *ngSwitchCase="'minlength'">
                This field requires a minimum of {{error.value.requiredLength}} characters.
            </ng-container>
            <ng-container *ngSwitchCase="'maxlength'">
                This field can have a maximum of {{error.value.requiredLength}} characters.
            </ng-container>
            <ng-container *ngSwitchCase="'cardDate'">
                <span *ngIf="error.value.invalid">Wrong date format. Use format: {{error.value.minValidDate}}</span>
                <span *ngIf="error.value.expired">Your card has expired. Please use another card to make an order.</span>
            </ng-container>
            <ng-container *ngSwitchDefault>
                <div #ref><ng-content select=".unknow-invalid-message"></ng-content></div>
                <span *ngIf="ref.children.length == 0">
                    Unsupported validation type. {{error.value | json}}
                </span>
            </ng-container>
        </div>
    </ng-template>

</div>