import { Injectable } from "@angular/core";
import { FormBuilder, FormGroup, Validators } from "@angular/forms";
import { BehaviorSubject } from "rxjs";
import {
  noWhitespaceValidator,
  RegularExpressionConstant,
} from "../../validators/validator";
// import { DEFAULT_STEPPER_STATE } from "../contants/order.contants";
import { STEPPERS_LIST } from "../contants/steps.constants";
import { StepMapping } from "../enums/steps.enum";
import { IAddress, IStep, IStepDetails } from "../models/steps.models";
import { CheckoutService } from "./checkout.service";

@Injectable({
  providedIn: "root",
})
export class StepperService {
  checkoutFormGroup: FormGroup;

  readonly stepSubject$: BehaviorSubject<IStep> = new BehaviorSubject<IStep>(
    STEPPERS_LIST[StepMapping.DEFAULT]
  );
  readonly currentStep = this.stepSubject$.asObservable();
  private readonly nextSubject$: BehaviorSubject<boolean> =
    new BehaviorSubject<boolean>(true);
  readonly nextStepStatus = this.nextSubject$.asObservable();
  private readonly prevSubject$: BehaviorSubject<boolean> =
    new BehaviorSubject<boolean>(true);
  readonly prevStepStatus = this.prevSubject$.asObservable();
  steps = STEPPERS_LIST;
  readonly stepDetailsSubject$: BehaviorSubject<IStepDetails> =
    new BehaviorSubject<IStepDetails>({});
  readonly stepDetails = this.stepDetailsSubject$.asObservable();

  pickUpAddress: IAddress | undefined;
  deliveryAddress: IAddress | undefined;

  constructor(
    private _fb: FormBuilder,
    private readonly checkoutService: CheckoutService
  ) {
    this.initForm();
    this.subscribeToStepDetailsSubject();
    this.subscribeToStepSubject();
  }

  initForm() {
    this.checkoutFormGroup = this._fb.group({
      addressFormGroup: this._fb.group({
        pickupAddress: ["", [Validators.required]],
        deliveryAddress: ["", [Validators.required]],
        pickupDate: ["", [Validators.required]],
        pickupTime: ["", [Validators.required]],
        deliveryDate: ["", [Validators.required]],
        deliveryTime: ["", [Validators.required]],
        specialInstructionPickup: [""],
        specialInstructionDelivery: [""],
        sameAsPickup: [true],
      }),
      serviceFormGroup: this._fb.group({}),
      contactDetailsFormGroup: this._fb.group({
        first_name: ["", [Validators.required, noWhitespaceValidator]],
        last_name: ["", [Validators.required, noWhitespaceValidator]],
        email: [
          "",
          [
            Validators.required,
            Validators.pattern(RegularExpressionConstant?.EMAIL),
          ],
        ],
        phone: [
          "",
          [
            Validators.required,
            noWhitespaceValidator,
            Validators.minLength(9),
            // Validators.maxLength(10),
          ],
        ],
        password: ["", [Validators.required, Validators.minLength(6)]],
      }),
      paymentFormGroup: this._fb.group({
        cvc: ["", [Validators.required]],
        exp_month: ["", [Validators.required]],
        exp_year: ["", [Validators.required]],
        name: ["", [Validators.required]],
        number: ["", [Validators.required]],
      }),
      promoForm: this._fb.group({
        couponCode: [""],
      }),
    });
  }

  private subscribeToStepDetailsSubject() {
    this.stepDetailsSubject$.subscribe((data) => {
      this.changeStatusOfhandlers(data);
    });
  }

  setStepDetails(details, reset: boolean = false) {
    let previousDetails = reset ? undefined : this.stepDetailsSubject$.value;
    this.stepDetailsSubject$.next({
      ...previousDetails,
      ...details,
    });
  }

  setCurrentStep(step: IStep = STEPPERS_LIST[0]) {
    this.stepSubject$.next(step);
  }

  moveToNextStep() {
    const currentStep = this.stepSubject$.value;
    const stepindex = this.steps.findIndex((el) => el === currentStep);
    if (stepindex + 1 < this.steps.length) {
      this.stepSubject$.next(this.steps[stepindex + 1]);
    }
  }

  moveToPrevStep() {
    const currentStep = this.stepSubject$.value;
    const stepindex = this.steps.findIndex((el) => el === currentStep);
    if (stepindex - 1 >= 0) {
      this.stepSubject$.next(this.steps[stepindex - 1]);
    }
  }

  setNextStepStatus(status: boolean) {
    this.nextSubject$.next(status);
  }

  setPrevStepStatus(status: boolean) {
    this.prevSubject$.next(status);
  }

  resetHandlers() {
    this.setNextStepStatus(true);
  }

  private subscribeToStepSubject() {
    this.stepSubject$.subscribe(() => {
      // POPULATE DATA IN CHECKOUT SEVICE
      this.pushDataInCheckoutService();
      if (this.stepSubject$.value.STEP_NUMBER > StepMapping.DEFAULT) {
        this.setPrevStepStatus(false);
      } else {
        this.setPrevStepStatus(true);
      }
    });
  }

  pushDataInCheckoutService() {
    let val = JSON.parse(JSON.stringify(this.stepDetailsSubject$.value));
    this.checkoutService.orderSummarySubject$.next(val);
  }

  private changeStatusOfhandlers(data: IStepDetails) {
    if (data[this.stepSubject$.value.STEP_NUMBER]) {
      this.setNextStepStatus(false);
    } else {
      this.setNextStepStatus(true);
    }
  }

  resetSteps() {
    this.stepSubject$.next(STEPPERS_LIST[StepMapping.DEFAULT]);
    this.steps = STEPPERS_LIST;
    this.stepDetailsSubject$.next({});
  }
}
